.subscription-new-banner-web-card {
    width: 100%;
    margin: auto;
    margin-top: 20px;
    border-radius: 8px !important;
    box-shadow: none !important;
}

.subscription-new-banner-web-content {
    background-image: url('../../assets/subscribe-banner.jpg');
    /* background-size: contain; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;
    height: 230px;
    position: relative;
}

.subscription-new-banner-web-content-tint {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    /* background-color: #416ad4; */
    background: linear-gradient(90deg, rgba(134, 79, 253, 1) 10%, rgba(24, 67, 160, 1) 100%);

    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.8;
}

.subscription-banner-web-text {
    font-family: 'Sk-Modernist' !important;
    font-size: 30px !important;
    font-weight: 700 !important;
    line-height: 40px !important;
    text-align: left;
    color: #f2f2f2 !important;
    margin-bottom: 6px !important;
}


.package-type {
    border: 1px solid #E2E2E2;
    border-radius: 12px;
    padding: 24px 30px;
    position: relative;
    /* Important: Ensure it's positioned */
    box-shadow: 0px 0px 30px 2px #00000005;
    background-color: white;
}

/* Create a half-circle using a pseudo-element */
.package-type::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    width: 101px;
    height: 72px;
    background-color: #1843A0;
    border-radius: 0px 13px 30px 106px;
    z-index: 1;
    opacity: 0.08;
}

/* Adjust the inner-box to handle the pseudo-element */
.package-type .inner-box {
    padding-bottom: 29px;
    border-bottom: 1px solid #E2E2E2;
    margin-bottom: 24px;
    position: relative;
    /* Ensure it's positioned */

}

/* Add hover effect */
/* .package-type:hover {
    background: linear-gradient(180deg, #864FFD 0%, #1843A0 100%);
    color: #fff !important;
    cursor: pointer;
    box-shadow: 0px 0px 30px 2px #00000005;

}

.package-type:hover .inner-box .plan-type,
.package-type:hover .inner-box .plan-price,
.package-type:hover .inner-box .plan-info,
.package-type:hover .package-list .check-icon.enabled,
.package-type:hover .inner-box .offer-ribbon,
.package-type:hover .package-list .list-text,
.package-type:hover .package-list .list-text-bold,
.package-type:hover .caption {
    color: #fff !important;

}

.package-type:hover .get-now-btn {
    background-color: transparent !important;
    border: 1px solid #fff !important;
    border-radius: 6px !important;
    padding: 14px 0px !important;
    font-family: Sk-Modernist !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    text-align: left !important;
    color: #fff !important;
    text-transform: capitalize !important;
} */

.inner-box .plan-type {
    font-family: 'Sk-Modernist' !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 21.6px !important;
    margin-bottom: 6px !important;
    color: #000;
}

.inner-box .offer-ribbon {
    font-family: Sk-Modernist;
    font-size: 11px;
    font-weight: 700;
    line-height: 13.2px;
    text-transform: uppercase;
    color: #fff;
    background-color: #F04D23;
    padding: 8px 10px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    margin-right: -30px;
    z-index: 2;
}

.inner-box .plan-price {
    font-family: 'Sk-Modernist' !important;
    font-size: 34px !important;
    font-weight: 700 !important;
    line-height: 40.8px !important;
    margin-bottom: 9px !important;
    color: #1843A0;
}

.inner-box .plan-info {
    font-family: Gotham !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 12.44px !important;
    color: #696969;

}

.package-type .package-list {
    list-style: none !important;
    padding-left: 0px;
}

.package-type .package-list li {
    display: flex;
    align-items: start;
    gap: 10px;
    margin-bottom: 20px;
}

.package-type .package-list .check-icon.enabled {
    width: 15px;
    height: 16px;
    color: #00B257;
}

.package-type .package-list .check-icon.disable {
    width: 15px;
    height: 16px;
    color: #9D9D9D !important;
}

.package-type .package-list .list-text {
    font-family: 'Gotham' !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    color: #696969;
}

.package-type .package-list .list-text.disable {
    font-family: 'Gotham' !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    color: #B8B6B6 !important;
}

.package-type .package-list .list-text-bold {
    color: #864FFD;
    font-weight: 700 !important;
}

.package-type .caption {
    font-family: Gotham !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 12.44px !important;
    color: #1843A0 !important;
    margin-bottom: 20px !important;
}

.get-now-btn {
    background-color: #1843A0 !important;
    border: 1px solid transparent !important;
    border-radius: 6px !important;
    padding: 14px 0px !important;
    font-family: Sk-Modernist !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    text-align: left !important;
    color: #fff !important;
    text-transform: capitalize !important;
}

.plan-price .now-text {
    font-family: Gotham !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 11.48px !important;
}

.plan-price .free-text {
    font-family: Gotham !important;
    font-size: 17px !important;
    font-weight: 700 !important;
    line-height: 16.27px !important;
}

.package-box .divider {
    opacity: 1;
    border: 1px solid #c5c5c5;
    margin-bottom: 15px;
}

.note-box {
    padding: 14px 25px;
    background-color: #d9d9d9;
    border-radius: 10px;
}

.note-box .note {
    color: #1843a0;
    font-family: Arimo Hebrew Subset Italic;
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    line-height: 14.95px;
    text-align: justified;
}



.package-box .header-subtitle {
    font-family: 'Gotham' !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    color: #5f5f5f !important;
    margin-bottom: 10px;
}


.credit-services-card {
    max-width: 100%;
    margin: auto;
    margin-top: 0px;
    border-radius: 8px !important;
    box-shadow: none !important;
    position: relative;
}

.credit-services-content {
    background-image: url('../../assets/credit-services-background.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px 20px !important;
    color: white;
}

.credit-services-card::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background: linear-gradient(270deg, rgba(134, 79, 253, 0) 0%, #864FFD 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    /* Ensure it is behind the content */
}

.marketing-services-card {
    max-width: 100%;
    margin: auto;
    margin-top: 0px;
    border-radius: 8px !important;
    box-shadow: none !important;
    position: relative;
}

.marketing-services-content {
    background-image: url('../../assets/marketing-services-background.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px 20px !important;
    color: white;

}

.marketing-services-card::after {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background: linear-gradient(270deg, rgba(134, 79, 253, 0) 0%, #864FFD 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.85;
}

.learn-more-btn {
    border-radius: 6px !important;
    background: #fff !important;
    color: #864ffd !important;
    font-size: 13px !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    font-family: 'Sk-Modernist' !important;
}



/* ToggleSwitch.css */


/* Label Styles */
.toggle-label {
    font-family: 'Gotham' !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: #393939 !important;
}

.toggle-label.active {
    font-family: 'Gotham' !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    color: #393939 !important;
}

@media (max-width: 990px) {

    /* Add styles here */
    .subscription-new-banner-card {
        width: 100%;
        margin: auto;
        margin-top: 20px;
        border-radius: 8px !important;
        box-shadow: none !important;
    }

    .subscription-new-banner-content {
        background-image: url('../../assets/subscribe-banner.jpg');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        color: white;
        height: 200px;
        padding: 24px 16px !important;
        position: relative;
    }

    .subscription-new-banner-content-tint {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        /* background-color: #416ad4; */
        background: linear-gradient(90deg, rgba(134, 79, 253, 1) 10%, rgba(24, 67, 160, 1) 100%);

        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 0.8;
    }

    .subscription-banner-text {
        font-family: 'Sk-Modernist' !important;
        font-size: 18px !important;
        font-weight: 700 !important;
        line-height: 21.6px !important;
        text-align: left;
        color: #f2f2f2 !important;
        margin-bottom: 6px !important;
    }

    .package-type {
        border: 1px solid #E2E2E2;
        border-radius: 12px;
        padding: 20px 18px;
        position: relative;
        box-shadow: 0px 0px 30px 2px #00000005;
        background-color: white;
    }

    .package-type .package-list .check-icon.enabled {
        width: 13px;
        height: 14px;
        color: #00B257;
    }

    .package-type .package-list .check-icon.disable {
        width: 13px;
        height: 14px;
        color: #9D9D9D !important;
    }

    .package-type .package-list .list-text {
        font-family: 'Gotham' !important;
        font-size: 12px !important;
        font-weight: 400 !important;
        line-height: 15px !important;
        color: #696969;
    }

    .package-type .package-list .list-text.disable {
        font-family: 'Gotham' !important;
        font-size: 12px !important;
        font-weight: 400 !important;
        line-height: 15px !important;
        color: #B8B6B6 !important;
    }

    .package-type .package-list .list-text-bold {
        color: #864FFD;
        font-weight: 700 !important;
    }

    /* SliderCard.css */

    .plan-slider-main {
        display: flex !important;
        flex-wrap: nowrap !important;
        justify-content: start !important;
        gap: 14px !important;
        overflow-x: auto !important;
        padding: 10px 0px !important;
    }

    .slider-card {
        background: linear-gradient(90deg, rgba(134, 79, 253, 1) 10%, rgba(24, 67, 160, 1) 100%);
        color: white !important;
        border-radius: 8px !important;
        padding: 16px 14px !important;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3) !important;
        transition: transform 0.3s ease-in-out !important;
        min-width: 160px !important;
        width: 100%;
        /* max-width: 160px !important; */
    }

    .slider-card:hover {
        transform: scale(1.05);
    }



    .slider-card-content {
        padding: 0px !important;
    }

    .slider-card-title {
        font-family: 'Sk-Modernist' !important;
        font-size: 15px !important;
        font-weight: 700 !important;
        line-height: 18px !important;
        margin-bottom: 6px !important;
    }

    .slider-card-price {
        font-family: Gotham !important;
        font-size: 14px !important;
        font-weight: 700 !important;
        line-height: 14.36px !important;
        margin-bottom: 4px !important;
    }

    .slider-card .now-text {
        font-family: Gotham !important;
        font-size: 10px !important;
        font-weight: 400 !important;
        line-height: 9.57px !important;
    }

    .slider-card-subtext {
        font-family: 'Sk-Modernist' !important;
        font-size: 12px !important;
        font-weight: 400 !important;
        line-height: 16px !important;
        margin-bottom: 7px !important;
    }

    .slider-card-offer-button {
        background-color: #ff4500;
        color: white;
        font-size: 12px;
        margin-top: 10px;
        padding: 4px 8px;
    }

    .slider-card-offer-ribbon {
        font-family: 'Sk-Modernist' !important;
        font-size: 9px !important;
        font-weight: 700 !important;
        line-height: 10.8px !important;
        text-transform: uppercase !important;
        color: #fff !important;
        background-color: #F04D23 !important;
        padding: 4px 10px !important;
        border-radius: 100px !important;
        width: max-content;

    }

    .slider-card .check-icon {
        position: absolute;
        bottom: 13px;
        right: 12px;
        font-size: 20px;
      }
}