body {
    margin: 0;
    font-family: 'Sk-Modernist';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: none;
}

.sweet-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    z-index: 1000;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.MuiTypography-root {
    font-family: 'Sk-Modernist' !important;
}

@font-face {
    font-family: 'Sk-Modernist';
    src: url('./fonts/Sk-Modernist/Sk-Modernist-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Sk-Modernist';
    src: url('./fonts/Sk-Modernist/Sk-Modernist-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sk-Modernist';
    src: url('./fonts/Sk-Modernist/Sk-Modernist-light.ttf') format('truetype');
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: 'Gotham';
    src: url('./fonts/Gotham/Gotham-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('./fonts/Gotham/Gotham-Book.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('./fonts/Gotham/Gotham-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('./fonts/Gotham/Gotham-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Manrope';
    src: url('./fonts/Manrope/Manrope-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.font-skmodernist {
    font-family: 'Sk-Modernist' !important;
}

.font-gotham {
    font-family: 'Gotham' !important;
}

.font-manrope {
    font-family: 'Manrope' !important;
}

.MuiInputBase-input {
    padding: 14px !important;
}

.MuiInputBase-input,
.MuiInputBase-input::placeholder {
    font-family: 'Gotham' !important;
    color: #727272 !important;
    font-size: 14px !important;
    opacity: 100% !important;
}

.cursor-pointer {
    cursor: pointer;
}

.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input .MuiSelect-select {
    font-family: 'Gotham' !important;
    color: #727272 !important;
    font-size: 14px !important;
    padding: 14px !important;
}

textarea {
    border-radius: 6px !important;
    border: 1px solid #d4d4d4 !important;
    padding: 14px !important;
    font-family: 'Gotham' !important;
    color: #727272 !important;
    font-size: 14px !important;
}

.MuiSelect-icon {
    color: rgba(0, 0, 0, 1) !important;
}

.text-black {
    color: #000 !important;
}

.text-light-white {
    color: #dee1e6 !important;
}

.text-lightgray {
    color: #939393 !important;
}

.text-offwhite {
    color: #f2f2f2 !important;
}

.text-grey {
    color: #727272 !important;
}

.text-gray {
    color: #757575 !important;
}

.text-gray2 {
    color: #828282 !important;
}

.text-darkgrey {
    color: #393939 !important;
}

.text-ashgrey {
    color: #4d4d4d;
}

.text-lightgrey {
    color: #acacac !important;
}

.text-slategrey {
    color: #656565 !important;
}

.text-slategray {
    color: #5e5e5e !important;
}

.text-primary {
    color: #416ad4 !important;
}

.text-primary-1 {
    color: #61a3f2 !important;
}

.text-primary-2 {
    color: #1843a0 !important;
}

.text-secondary {
    color: #864ffd !important;
}

.text-mandatory {
    color: #f00000 !important;
}

.text-breadcrumbs-active {
    color: #1843a0 !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.text-justify {
    text-align: justify !important;
    text-justify: inter-word !important;
}

.py-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
}

.my-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
}

.MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: 14px;
    color: #393939;
    font-family: 'Gotham' !important;
}

.main-divider {
    /* margin-right: -16px !important;
	margin-left: -16px !important; */
    border: 1px solid #dedede;
}

.divider {
    border: 1px solid #dedede;
}

/* Accordion section starts here */

.custom-accordion {
    margin: 15px -16px !important;
    border-top: 2px solid #dedede;
}

.custom-accordion .MuiAccordion-root {
    box-shadow:
        inset 2px 0px 1px -1px #fff,
        0px 1px 1px 0px #dedede,
        0px 1px 3px 0px #fff !important;
}

.custom-accordion .MuiAccordion-root:last-of-type,
.custom-accordion .MuiAccordion-root:first-of-type {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.custom-accordion .MuiAccordion-root.Mui-expanded {
    margin: 0px !important;
}

.custom-accordion.mobile-accordion .MuiAccordion-root.Mui-expanded {
    margin-top: 12px !important;
}

.custom-accordion.mobile-accordion .expand-more-icon {
    margin-top: 12px;
}

.custom-accordion.mobile-accordion .MuiAccordionSummary-expandIconWrapper.Mui-expanded .expand-more-icon {
    margin: 0px !important;
}

.custom-accordion.mobile-accordion .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
    min-height: 0px !important;
    margin-bottom: 12px;
}

.custom-accordion .MuiAccordionSummary-content .MuiListItemText-primary {
    color: var(--Gray, #393939) !important;
    font-family: 'Sk-Modernist' !important;
    font-size: 15px;
}

.custom-accordion .MuiAccordionSummary-content.Mui-expanded {
    /* margin: 12px 0 0 0; */
    margin: 0px;
}

/* .MuiAccordion-root.Mui-expanded:last-of-type {
	border-top: 1px solid #dedede !important;
} */

.custom-accordion .MuiAccordionDetails-root {
    color: #757575;
    font-family: 'Gotham' !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border-bottom: 1px solid #dedede;
    padding: 0px 26px 16px 36px;
    text-align: start;
}

.custom-accordion .MuiListItemIcon-root {
    min-width: 20px !important;
}

.custom-accordion .MuiListItemText-root {
    margin-top: 1px;
    color: #393939;
    font-family: 'Sk-Modernist' !important;
    font-size: 15px;
}

/* Accordion section ends here */

/* Radio section starts here */

.MuiRadio-root {
    color: #393939 !important;
}

.MuiRadio-root.Mui-checked {
    color: #416ad4 !important;
}

/* Radio section ends here */

.add-button {
    border: 2px dashed #61a3f2 !important;
    padding: 14px 20px !important;
}

.social-add-button {
    width: 49px !important;
    height: 49px !important;
    margin-left: 10px !important;
    border: 1px solid #61a3f2 !important;
    border-radius: 6px !important;
}

/* Date field */
.MuiStack-root .MuiTextField-root {
    width: 100% !important;
}

/* Date field ends here */

/* Catalog section starts here */

.upload-image-container {
    border: 2px dashed #61a3f2 !important;
    border-radius: 6px !important;
    min-height: 100px;
    padding: 10px;
    display: flex;
    /* grid: 80px / auto auto auto auto; */
}

.image-inner-field {
    width: 78px !important;
    height: 80px !important;
    border-radius: 6px;
    margin-right: auto;
    position: relative;
}

.product-image {
    width: 78px;
    height: 80px;
    border-radius: 6px;
    background: #d9d9d9;
}

.product-close-icon {
    position: absolute;
    top: -5px;
    right: -5px;
}

.product-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Adjust the minimum width as needed */
    gap: 20px;
    /* Equal gap between images */
}

.product-grid .image-inner-field {
    width: 100px !important;
    height: 100px !important;
    position: relative;
    border-radius: 6px;
}

.product-grid .product-close-icon {
    position: absolute;
    top: -5px;
    right: -5px;
}

.product-grid .product-image {
    width: 100px;
    /* Ensure the image takes up the entire width of the container */
    height: 100px;
    /* Maintain the aspect ratio */
}

.catalog-card {
    max-width: 100%;
    margin: auto;
    margin-top: 20px;
    border-radius: 8px !important;
    box-shadow: none !important;
}

.catalog-content {
    background-image: url('assets/catalog-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 25px 20px !important;
    color: white;
    /* Text color on top of the background image */
}

.add-product-btn {
    border-radius: 6px !important;
    background: #fff !important;
    color: #393939 !important;
    font-size: 13px !important;
    text-transform: none !important;
    font-weight: 700 !important;
    font-family: 'Sk-Modernist' !important;
}

.list-item {
    margin-bottom: 16px;
    padding: 0px !important;
    cursor: pointer;
}

.list-item .card {
    display: flex;
    flex-direction: row !important;
    width: 100% !important;
    padding: 12px !important;
    box-shadow: none !important;
    border-radius: 8px !important;
    border: 1px solid #e3e3e3 !important;
}

.list-item .media {
    width: 120px;
    height: 110px;
    border-radius: 8px;
    box-shadow: 2px 1px 18px 3px #00000014;
}

.list-item .content {
    flex: 1;
    padding: 0px 0px 0px 10px !important;
}

.list-item .actions {
    display: flex;
    gap: 8px;
    margin-top: 8px;
}

.list-item .actions .cat-edit-icon {
    padding: 0px !important;
}

.list-item .actions .cat-delete-icon {
    padding: 0px !important;
}

/* Catalog section ends here */

/* Catalog Filter Section Starts Here */
.rct-collapse {
    height: 27px;
}

.rct-text {
    display: flex;
}

.react-checkbox-tree .rct-text label {
    display: inherit !important;
}

.rct-node-icon {
    display: none;
}

.rct-title {
    padding: 0 5px;
    color: #727272 !important;
    font-family: Gotham !important;
    font-size: 15px !important;
    word-wrap: break-word;
    white-space: normal;
    flex: 1;
    line-height: 24px;
    margin-top: 2px;
}

.rct-checkbox {
    color: #D4D4D4;
}

.rct-checkbox .rct-icon {
    width: 24px;
    height: 24px;
    font-size: 19px;
}

span.rct-icon.rct-icon-check {
    color: #416ad4;
}
/* Catalog Filter Section Ends Here */

/* Homepage section starts here */

.MuiToolbar-root {
    padding: 0px !important;
    /* App bar */
}

.MuiAppBar-root {
    background: transparent !important;
    box-shadow: none !important;
}

.MuiAutocomplete-root {
    background: white !important;
    border-radius: 6px !important;
}

.MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0px !important;
}

.MuiAutocomplete-input {
    /* padding-left: 0px !important; */
}





/* .homepage-background {
    background-image: url('assets/Homepage-Header960x560px.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: auto;
    margin: -16px;
    padding: 25px 16px;
    position: relative;
} 

.homepage-background-tint {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-color: #7165eb;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.85;
} */

.homepage-background {
    background: linear-gradient(180deg, #1843A0 0%, #864FFD 100%);
    height: auto;
    margin: -16px;
    padding: 25px 16px 44px 16px;
    position: relative;
}

.homepage-background-tint {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-image: url('assets/web-homepage-vector.svg');
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
}

/* .homepage-web-background {
    background-image: url('assets/Homepage-Header2880x1020px.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: auto;
    padding: 36px 0 130px 0px;
    position: relative;
} */

/* .homepage-web-background-tint {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-color: #7165eb;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.85;
} */

.homepage-web-background {
    background: linear-gradient(180deg, #1843A0 0%, #864FFD 100%);
    height: auto;
    padding: 25px 0 44px 0px;
    position: relative;
}

.homepage-web-background-tint {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-image: url('assets/web-homepage-vector.svg');
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
}



.homepage-web-background .MuiChip-root {
    border-radius: 4px;
    background: #f5f1fe;
    color: #864ffd;
    font-family: 'Gotham' !important;
    font-size: 13px;
}

.btn-search {
    padding: 14px 24px !important;
    background: #1843a0 !important;
    font-family: 'Sk-Modernist' !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    line-height: 16.8px !important;
    text-transform: capitalize !important;
    border-radius: 6px !important;
}

.homepage-background .MuiChip-root {
    border-radius: 4px;
    background: #f5f1fe;
    color: #864ffd;
    font-family: 'Gotham' !important;
    font-size: 13px;
}

.verified-freelancers.MuiGrid-root {
    width: 100% !important;
    margin-left: 0px !important;
    background: #f5f4fa !important;
}

.login-btn {
    border-radius: 6px !important;
    color: #121212 !important;
    background: #fff !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    font-family: 'Sk-Modernist' !important;
    line-height: 16.8px !important;
    /* height: 35px; */
    padding: 14px 24px !important;
}

.subscribe-now-btn {
    border-radius: 6px !important;
    color: #121212 !important;
    background: #fff !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    font-family: 'Sk-Modernist' !important;
    line-height: 16.8px !important;
    /* height: 35px; */
    padding: 14px 24px !important;
}

.elynker-services-slider {
    display: flex;
    max-width: auto;
    overflow: auto;
    scroll-behavior: smooth;
    transition: scroll 0.3s ease-in-out;
}

.elynker-web-services-slider {
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin: 0 auto;
    overflow-x: scroll;
    scroll-behavior: smooth;
    transition: scroll 0.3s ease-in-out;
}

.elynker-services-slider .images-container,
.elynker-web-services-slider .images-container {
    position: relative;
}

.elynker-services-slider .image-text,
.elynker-web-services-slider .image-text {
    position: absolute;
    bottom: 16px;
    left: 18px;
    font-size: 15px;
    font-family: 'Sk-Modernist' !important;
    font-weight: 700;
    color: #000;
}


.elynker-services-slider .image, .elynker-web-services-slider .image {
    width: 170px;
    height: 250px;  /*210px*/
    margin: 5px;
    border-radius: 8px;
    object-fit: contain;
    background: white;
    border: 1px solid #ececec;
    box-shadow: 0px 0px 4px 0px #c4bdbd4d;
}

.key-list-items .MuiListItemText-primary {
    font-family: 'Gotham' !important;
    font-size: 15px;
    font-weight: 500 !important;
    color: #757575 !important;
}

.key-list-items .MuiListItemText-secondary {
    font-family: 'Gotham' !important;
    font-size: 15px;
    font-weight: 400 !important;
    color: #757575 !important;
}


/* Customize scrollbar */
.elynker-services-slider::-webkit-scrollbar {
    width: 8px;
    /* Width of the scrollbar */
    height: 0px;
    /* height: 5px; */
}

.elynker-services-slider::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Track color */
}

.elynker-services-slider::-webkit-scrollbar-thumb {
    background: #888;
    /* background: #864FFD; */
    border-radius: 4px;
    /* Rounded corners */
}

.elynker-services-slider::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Thumb color on hover */
}

.looking-for-card {
    max-width: 100%;
    margin: auto;
    margin-top: 20px;
    border-radius: 8px !important;
    box-shadow: none !important;
}

.looking-for-content {
    background-image: url('assets/looking-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0px !important;
    color: white;
    text-align: start;
}

.looking-for-web-card {
    margin: auto;
    margin-top: 20px;
    box-shadow: none !important;
    width: 100%;
    height: 100%;
}

.looking-for-web-content {
    background-image: url('assets/why-elynker-banner.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0px !important;
    color: white;
    text-align: start;
    width: 50%;
    height: auto;
}

.looking-for-web-service {
    background-image: url('assets/looking-web-banner.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0px !important;
    color: white;
    text-align: start;
    width: 50%;
    height: auto;
}

.MuiBox-root.inner-content {
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 50px;
}

.post-requirement-btn {
    border-radius: 6px !important;
    background: #1843a0 !important;
    color: #fff !important;
    font-size: 13px !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    font-family: 'Sk-Modernist' !important;
}

.business-tools-slider {
    display: flex;
    max-width: 500px;
    overflow: scroll;
    scroll-behavior: smooth;
    transition: scroll 0.3s ease-in-out;
}

.business-tools-web-slider {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    scroll-behavior: smooth;
    transition: scroll 0.3s ease-in-out;
}

.business-tools-slider .images-container,
.business-tools-web-slider .images-container {
    position: relative;
}

.business-tools-slider .image-text,
.business-tools-web-slider .image-text {
    font-size: 15px;
    font-family: 'Sk-Modernist' !important;
    font-weight: 400;
    color: #393939;
    transform: translateX(5px);
}

.business-tools-slider .image,
.business-tools-web-slider .image {
    width: 175px;
    height: 170px;
    margin: 5px;
    border-radius: 8px;
}

.business-tools-slider::-webkit-scrollbar {
    width: 8px;
    /* Width of the scrollbar */
    height: 5px;
}

.business-tools-slider::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Track color */
}

.business-tools-slider::-webkit-scrollbar-thumb {
    background: #888;
    /* background: #864FFD; */
    border-radius: 4px;
    /* Rounded corners */
}

.business-tools-slider::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Thumb color on hover */
}

.elynker-for-list .MuiListItem-root {
    padding: 1px 0px !important;
}

.elynker-for-list .MuiListItemIcon-root {
    min-width: 25px !important;
    margin-top: 3px;
}

.elynker-for-list .list-text .MuiListItemText-primary {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #393939 !important;
}

.modal-carousel {
    max-width: 600px;
}

.modal-carousel .carousel .slider-wrapper.axis-horizontal .slider {
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
    align-items: center !important;
}

.carousel-field {
    border-radius: 8px;
    background-image: url('assets/Tools-List-Header2340x400px.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    padding: 20px !important;
    min-height: 260px;
    border-radius: 10px !important;
    position: relative;
}

.carousel-field-tint {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-color: #7165eb;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.85;
}

.carousel-web-field {
    min-height: 250px;
    text-align: center;
    width: 650px;
    margin: 0 auto;
}

.carousel-container {
    margin-left: 1px;
    margin-right: 3px;
}

.carousel-container .carousel-slider {
    display: flex;
    flex-direction: column-reverse;
}

.carousel.carousel-slider .control-arrow {
    opacity: 0 !important;
}

.carousel-container .carousel .control-dots {
    position: relative;
    margin: 0;
}

.carousel-container .carousel .control-dots .dot.selected,
.carousel-container .carousel .control-dots .dot:hover {
    opacity: 1;
    width: 16px;
    border-radius: 6px;
    filter: alpha(opacity=100);
}

.carousel-container .carousel .control-dots .dot {
    transition: opacity 0.25s ease-in;
    opacity: 0.3;
    filter: alpha(opacity=30);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
    background: #864ffd;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
}

.carousel-status {
    display: none;
}

.carousel .thumbs-wrapper {
    display: none;
}

.business-service-card {
    max-width: 100%;
    margin: 0;
    margin-bottom: 20px;
    margin-top: 20px;
    border-radius: 8px !important;
    box-shadow: none !important;
    /* transform: translateY(70px); */
}

.business-service-content {
    background-image: url('assets/business-service-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    padding: 16px !important;
    color: white;
    /* display: flex; */
    width: 70%;
    align-items: center;
    justify-content: space-between;
}

.business-service-web-card {
    max-width: 100%;
    margin: 0;
    margin-bottom: 20px;
    margin-top: 20px;
    border-radius: 8px !important;
    box-shadow: none !important;
    /* transform: translateY(70px); */
}

.business-service-web-content {
    background-image: url('assets/business-service-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    padding: 40px 20px 20px 40px !important;
    color: white;
    /* display: flex; */
    width: 70%;
    align-items: center;
    justify-content: space-between;
}

.business-service-btn {
    border-radius: 6px !important;
    color: #864ffd !important;
    background: #fff !important;
    font-size: 13px !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    font-family: 'Sk-Modernist' !important;
    height: 40px;
    padding: 10px 10px !important;
}

.homepage-footer {
    background-color: #1d1d1d;
    height: auto;
    padding: 60px 35px 0 35px;
    /* margin: 0 -16px; */
    margin: 0px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.footer-divider {
    margin-right: -34px !important;
    margin-left: -34px !important;
    height: 1px;
    background: #3b3b3b;
}

.homepage-footer-content {
    justify-content: space-between;
}

.complete-profile-card {
    max-width: 100%;
    margin: 0;
    margin-bottom: 20px;
    border-radius: 8px !important;
    border: 1px solid #e2e2e2 !important;
    box-shadow: none !important;
}

.complete-profile-content {
    background: white;
    padding: 20px 15px !important;
    color: black;
    /* Text color on top of the background image */
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.complete-profile-web-card {
    max-width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
    border-radius: 8px !important;
    border: 1px solid #e2e2e2 !important;
    box-shadow: none !important;
}

.complete-profile-web-content {
    background: white;
    padding: 30px 35px !important;
    color: black;
    /* Text color on top of the background image */
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.complete-profile-btn {
    border-radius: 6px !important;
    background: #864ffd !important;
    color: #fff !important;
    font-size: 13px !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    font-family: 'Sk-Modernist' !important;
    padding: 10px 16px !important;
}

.progress-percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.insights-card {
    max-width: 100%;
    margin: 0;
    margin-bottom: 20px;
    border-radius: 8px !important;
    border: 2px solid #e3e3e3 !important;
    box-shadow: none !important;
}

.insights-content {
    padding: 20px 15px !important;
}

.profile-review-card {
    max-width: 100%;
    margin: 0;
    margin-bottom: 20px;
    border-radius: 8px !important;
    border: 2px solid #e3e3e3 !important;
    box-shadow: none !important;
    background: #864ffd !important;
}

.profile-review-content {
    padding: 10px !important;
}

.profile-review-content .ok-btn {
    border-radius: 6px !important;
    background: rgba(255, 255, 255, 0.24) !important;
    color: #fff !important;
    font-size: 16px !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    font-family: 'Sk-Modernist' !important;
    padding: 8px 10px !important;
    height: 38px;
}

.text-gain {
    color: #00b257 !important;
}

.text-connects {
    color: #a7a7a7 !important;
}

.credit-services-card {
    max-width: 100%;
    margin: auto;
    margin-top: 20px;
    border-radius: 8px !important;
    box-shadow: none !important;
    cursor: pointer;
}

.credit-services-content {
    background-image: url('assets/credit-services-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px 20px !important;
    color: white;
    /* Text color on top of the background image */
}

.social-media-services-content {
    background-image: url('assets/social-media-service.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px 20px !important;
    color: white;
    /* Text color on top of the background image */
}

.other-services-content {
    background-image: url('assets/credit-services-bg-old.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px 20px !important;
    color: white;
    /* Text color on top of the background image */
}

.esential-services-content {
    background-image: url('assets/essential.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px 20px !important;
    color: white;
    /* Text color on top of the background image */
}

.freeportfolio-media-services-content {
    background-image: url('assets/freeportfolio.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px 20px !important;
    color: white;
    /* Text color on top of the background image */
}

.post-job-content {
    background-image: url('assets/PostaJob-Card2340x400px.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px 20px !important;
    color: white;
    position: relative;
    /* Text color on top of the background image */
}

.post-job-content-tint {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    /* background-color: #7165eb; */
    background-image: linear-gradient(to right, #7165eb, #ffffff00);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.85;
}

.learn-more-btn {
    border-radius: 6px !important;
    background: #fff !important;
    color: #864ffd !important;
    font-size: 13px !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    font-family: 'Sk-Modernist' !important;
}


.start-growing-btn {
    border-radius: 6px !important;
    background: #fff !important;
    color: #864ffd !important;
    font-size: 18px !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    font-family: 'Sk-Modernist' !important;
    padding: 10px 28px !important;
}

.digital-marketing-card {
    max-width: 100%;
    margin: auto;
    margin-top: 20px;
    border-radius: 8px !important;
    box-shadow: none !important;
}

.digital-marketing-content {
    background-image: url('assets/digital-marketing-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px 20px !important;
    color: white;
    /* Text color on top of the background image */
}

/* Homepage section ends here */

/* Profile Details starts here */
.singleValue {
    font-family: 'Gotham' !important;
    color: #727272 !important;
    font-size: 14px !important;
}

/* Profile details ends here */

/* B2B Business section starts here */

.b2b-business .MuiChip-root {
    border-radius: 4px !important;
    background: #f0f0f0 !important;
    color: #1843a0;
    font-family: 'Gotham' !important;
    font-size: 13px;
}

.b2b-business-slider::-webkit-scrollbar {
    width: 8px;
    /* Width of the scrollbar */
    height: 5px;
}

.business-testimonial-card {
    max-width: 100%;
    margin: auto;
    /* margin-top: 20px; */
    border-radius: 8px !important;
    box-shadow: none !important;
}

.business-testimonial-content {
    background-image: url('assets/Business-List-Header764x300px.png');
    /* background-size: contain; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* padding: 0px !important; */
    padding: 5px !important;
    color: white;
    height: 172px;
    position: relative;
}

.business-testimonial-content-tint {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-color: #7165eb;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.85;
}

.business-testimonial-text {
    font-size: 16px !important;
    position: relative;
    top: 40px;
    left: 20px;
}

.business-testimonial-web-card {
    width: 100%;
    margin: auto;
    margin-top: 20px;
    border-radius: 8px !important;
    box-shadow: none !important;
}

.business-testimonial-web-content {
    background-image: url('assets/Business-List-Header2340x400px.png');
    /* background-size: contain; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 80px 0px 25px 50px !important;
    color: white;
    height: 230px;
    position: relative;
}

.business-testimonial-web-content-tint {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-color: #7165eb;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.85;
}

/* B2B Business section end here */

/* B2B Category Section starts here */
.category-card {
    max-width: 100%;
    margin: auto;
    /* margin-top: 20px; */
    border-radius: 8px !important;
    box-shadow: none !important;
}

.category-content {
    background-image: url('assets/B2B-category-bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    /* padding: 40px 0px 25px 20px !important; */
    padding: 0px !important;
    color: white;
    height: 172px;
}

.b2b-category-text {
    position: relative;
    top: 35px;
    left: 20px;
    font-size: 16px !important;
}

.b2b-category-button {
    position: relative;
    top: 32px;
    left: 20px;
}

.category-web-content {
    background-image: url('assets/b2b-category-web-bg.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    padding: 93px 0px 25px 60px !important;
    color: white;
    height: 240px;
}

.fl-category-web-content {
    background-image: url('assets/freelance-web-category-banner.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    padding: 93px 0px 25px 60px !important;
    color: white;
    height: 240px;
}

.category-web-card {
    width: 100%;
    margin: auto;
    /* margin-top: 20px; */
    border-radius: 8px !important;
    box-shadow: none !important;
}

.b2b-list-item {
    margin-bottom: 16px;
    padding: 0px !important;
}

.b2b-list-item .card {
    /* display: flex; */
    /* flex-direction: row !important; */
    width: 100% !important;
    padding: 14px 14px 16px 14px !important;
    box-shadow: none !important;
    border-radius: 8px !important;
    border: 1px solid #e3e3e3 !important;
}

.b2b-list-item .content {
    width: 100%;
    margin-left: 15px;
}

.b2b-list-item .category-media {
    width: 93px;
    height: 89px;
    border-radius: 50%;
}

.b2b-filter-section .MuiChip-root {
    border-radius: 4px !important;
    background: #fff !important;
    color: #1843a0 !important;
    font-family: 'Gotham' !important;
    font-size: 13px !important;
    border: 1px solid #1843a0;
    height: 36px !important;
}

.b2b-filter-saved-section.b2b-filter-section .MuiChip-root.MuiChip-saved-root {
    border-radius: 4px !important;
    background-color: #1843a0 !important;
    color: #ffffff !important;
    font-family: 'Gotham' !important;
    font-size: 13px;
    border: 1px solid #fff !important;
    height: 36px;
}

.b2b-filter-section .MuiChip-root .MuiChip-icon {
    margin-left: 9px;
}

.b2b-filter-section .MuiChip-root.selected-city {
    border-radius: 4px !important;
    background: #1843a0 !important;
    color: #fff;
    font-family: 'Gotham' !important;
    font-size: 13px;
    height: 36px;
}

.b2b-filter-section .dropdown-icon {
    color: #1843a0 !important;
}

.b2b-filter-slider::-webkit-scrollbar {
    width: 8px;
    /* Width of the scrollbar */
    height: 5px;
}

.b2b-filter-slider::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Track color */
}

.b2b-filter-slider::-webkit-scrollbar-thumb {
    background: #888;
    /* background: #864FFD; */
    border-radius: 4px;
    /* Rounded corners */
}

.b2b-filter-slider::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Thumb color on hover */
}

.call-now-btn {
    border-radius: 6px !important;
    background: #fff !important;
    color: #864ffd !important;
    border: 1px solid #864ffd !important;
    width: 47% !important;
    padding: 9px 16px !important;
    letter-spacing: 1px !important;
    text-transform: capitalize !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    font-family: 'Sk-Modernist' !important;
}

.whatsapp-btn {
    border-radius: 6px !important;
    background: #864ffd !important;
    color: #fff !important;
    font-size: 13px !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    font-family: 'Sk-Modernist' !important;
    width: 47% !important;
    padding: 9px 16px !important;
    letter-spacing: 1px !important;
}

.b2b-list-detail-web-item {
    margin-bottom: 16px;
    padding: 0px !important;
}

.b2b-list-detail-web-item .card {
    width: 100% !important;
    padding: 20px 30px;
    box-shadow: none !important;
    border-radius: 8px !important;
    border: 1px solid #e3e3e3 !important;
}

.call-now-web-btn {
    border-radius: 6px !important;
    background: #fff !important;
    color: #864ffd !important;
    border: 1px solid #864ffd !important;
    /* width: 47% !important; */
    height: 40px;
    padding: 9px 16px !important;
    letter-spacing: 1px !important;
    text-transform: capitalize !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    font-family: 'Sk-Modernist' !important;
}

.whatsapp-web-btn {
    border-radius: 6px !important;
    background: #864ffd !important;
    color: #fff !important;
    font-size: 13px !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    font-family: 'Sk-Modernist' !important;
    /* width: 47% !important; */
    height: 40px;
    padding: 9px 16px !important;
    letter-spacing: 1px !important;
}

.filter-tabs .MuiTabs-indicator {
    background: #864ffd !important;
}

/* .filter-tabs .Mui-selected {
    color: #864FFD !important;
    font-weight: 400 !important;
} */

.filter-tabpanel {
    width: 70% !important;
}

.filter-tabpanel .MuiBox-root {
    padding: 0px !important;
}

.filter-tabpanel-list {
    padding-left: 20px !important;
    height: 220px;
    overflow-y: scroll;
}

.filter-tabpanel-list>.MuiListItem-root {
    border-bottom: 1px solid #eaeaea;
}

.filter-tabpanel-list .MuiListItemButton-root {
    padding-right: 0px !important;
}

.filter-tabpanel-list .MuiTypography-root {
    font-size: 14px !important;
    color: #727272 !important;
    font-family: 'Sk-Modernist' !important;
}

/* B2B Category Section ends here */

/* B2B WebCategory Section Starts Here */
.filter-web-list {
    padding-left: 0px !important;
}

.filter-web-list .MuiListItemButton-root {
    padding: 0px !important;
}

.filter-web-list .MuiListItemIcon-root {
    min-width: 0px !important;
}

.filter-web-list .MuiTypography-root,
.filter-web-sort-list .MuiTypography-root {
    font-size: 15px !important;
    color: #727272 !important;
    font-family: 'Gotham' !important;
}

.filter-web-sort-list {
    padding-left: 0px !important;
}

.filter-web-sort-list .MuiListItemIcon-root {
    min-width: 35px !important;
}

.filter-web-sort-list .MuiButtonBase-root.MuiRadio-root {
    padding: 0px !important;
}

.MuiRadio-root {
    color: #d4d4d4 !important;
}

.modal-footer.MuiDialogActions-root {
    padding: 10px 24px 20px 24px !important;
}

.cursor-pointer {
    cursor: pointer;
}

.subscription-banner-main {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 40px;
}

/* B2B WebCategory Section Ends Here */
@media (max-width: 375px) {
    .pagination-gap .MuiPagination-ul{
        gap: 3px !important;
    }

    .pagination-gap .MuiPagination-ul .MuiButtonBase-root.MuiPaginationItem-root{
        font-size: 14px !important;
    }
}

@media only screen and (max-width: 600px) {
    .subscription-banner-main {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 40px;
    }

    .b2b-filter-slider::-webkit-scrollbar {
        width: 8px;
        /* Width of the scrollbar */
        height: 0px;
    }

    .b2b-filter-slider::-webkit-scrollbar-track {
        background: #f1f1f1;
        /* Track color */
    }

    .b2b-filter-slider::-webkit-scrollbar-thumb {
        background: transparent;
        /* background: #864FFD; */
        border-radius: 4px;
        /* Rounded corners */
    }

    .b2b-filter-slider::-webkit-scrollbar-thumb:hover {
        background: #555;
        /* Thumb color on hover */
    }

    .product-catalog-mobile-list {
        width: 100%;
        max-width: 400px;
        margin: 0 auto !important;
        background-color: #fff;
    }

    .product-catalog-mobile-list .paper {
        padding: 16px;
        margin: auto;
        max-width: 400px;
        box-shadow: none;
    }

    .product-catalog-mobile-list .media {
        width: 142px;
        height: 176px;
    }

    .product-catalog-mobile-list .content {
        border: 2px solid #dcdcdc;
        padding: 10px;
        height: 176px;
    }

    .product-catalog-mobile-list .price-btn {
        color: #864ffd;
        font-size: 15px;
        font-family: 'Sk-Modernist' !important;
        font-weight: 700;
        line-height: 20px;
        padding: 0px !important;
        background-color: transparent;
        box-shadow: none;
    }

    .pagination-gap .MuiPagination-ul{
        gap: 10px;
    }

    .pagination-gap .MuiPagination-ul .MuiButtonBase-root.MuiPaginationItem-root{
        font-size: 16px !important;
        padding: 5px !important;
    }
}

@media (min-width: 460px) and (max-width: 767px) {
    .category-content {
        background-image: url('assets/b2b-category-web-bg-767.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        /* padding: 40px 0px 25px 20px !important; */
        padding: 0px !important;
        color: white;
        height: 165px;
    }

    .category-content {
        background-image: url('assets/b2b-category-web-bg-767.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        /* padding: 40px 0px 25px 20px !important; */
        padding: 0px !important;
        color: white;
        height: 165px;
        ;
    }

    /* .category-content {
        background-image: url('assets/b2b-category-web-bg-767.png');
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        color: #fff;
        padding: 0!important;
        height: 172px;
        
    } */


    /* .b2b-category-text br {
        display: none;

    } */
    .pagination-gap .MuiPagination-ul{
        gap: 10px;
    }

    .pagination-gap .MuiPagination-ul .MuiButtonBase-root.MuiPaginationItem-root{
        font-size: 16px !important;
        padding: 5px !important;
    }
}

/* Profile Section Starts Here */

.profile-main-card {
    max-width: 100%;
    /* margin-top: 70px; */
    margin-top: 140px;
    margin-bottom: 20px;
    border-radius: 8px !important;
    border: 2px solid #e3e3e3 !important;
    box-shadow: none !important;
    align-items: center;
    text-align: center;
    overflow: inherit !important;
}

.profile-main-content {
    padding: 20px 15px !important;
    position: relative;
}

.profile-web-sec {
    border: 1px solid #e2e2e2;
    border-radius: 8px;
    padding: 20px;
}

.profile-web-background {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 360px;
    border-radius: 8px;
    position: relative;
}

.profile-web-background-tint {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-color: #7165eb;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.85;
}

.profile-web-sec .profile-photo-web-main {
    width: 150px;
    height: 150px;
    transform: translate(20px, -50px);
    /* position: absolute; */
}

/* .profile-photo-main {
	position: absolute;
	right: 34%;
	top: -57px;
} */

.profile-photo-main {
    position: absolute;
    top: -57px;
    width: 100%;
    left: 0px;
}

.profile-photo-inner {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    position: relative;
}

.profile-avatar-img .MuiAvatar-img {
    object-fit: contain;
    border-radius: 50%;
}

.profile-edit-icon {
    position: absolute;
    bottom: 0;
    right: 0;
}

.m-top {
    margin-top: 60px !important;
}

.MuiGrid-root.profile-stats-card {
    width: 100%;
    margin: 0;
}

.stats-number {
    transform: translateY(-16px) !important;
}

.stats-text {
    transform: translateY(-10px) !important;
}

.my-catalog-card {
    max-width: 100%;
    margin: auto;
    /* margin-top: 20px; */
    border-radius: 8px !important;
    box-shadow: none !important;
}

.my-catalog-content {
    background-image: url('assets/catalog-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 25px 20px !important;
    color: white;
    /* Text color on top of the background image */
}

.profile-card-btn {
    border-radius: 6px !important;
    background: #fff !important;
    color: #393939 !important;
    font-size: 13px !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    font-family: 'Sk-Modernist' !important;
}

.free-tier-card {
    max-width: 100%;
    margin: auto;
    /* margin-top: 20px; */
    border-radius: 8px !important;
    box-shadow: none !important;
}

.free-tier-content {
    background-image: url('assets/free-tier-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 25px 20px !important;
    color: white;
    /* Text color on top of the background image */
}

.my-requirements-card {
    max-width: 100%;
    margin: auto;
    /* margin-top: 20px; */
    border-radius: 8px !important;
    box-shadow: none !important;
}

.my-requirements-content {
    background-image: url('assets/my-requirements-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 25px 20px !important;
    color: white;
    /* Text color on top of the background image */
}

.manage-website-card {
    max-width: 100%;
    margin: auto;
    /* margin-top: 20px; */
    border-radius: 8px !important;
    box-shadow: none !important;
}

.manage-website-content {
    background-image: url('assets/website-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 25px 20px !important;
    color: white;
    /* Text color on top of the background image */
}

.profile-complete-card {
    max-width: 100%;
    margin: auto;
    margin-top: 20px;
    border-radius: 8px !important;
    box-shadow: none !important;
}

.profile-complete-content {
    background-image: url('assets/complete-profile-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 25px 20px !important;
    color: white;
    /* Text color on top of the background image */
}

.logout-btn {
    border-radius: 6px !important;
    border: 1.5px solid #f04d23 !important;
    background: #fff !important;
    color: #f04d23 !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    font-family: 'Sk-Modernist' !important;
    padding: 10px 0px 11px !important;
}

.profile-background {
    background-image: url('assets/profile_cover_bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 370px;
    margin: -17px;
    padding: 25px 16px;
}

.profile-background-2 {
    /* background-image: url('assets/profile_cover_bg.jpg'); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 370px;
    margin: -17px 0px;
    padding: 25px 16px;
    position: relative;
}

.profile-background-2-tint {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-color: #7165eb;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.85;
}

.edit-cover-btn {
    color: #fff !important;
    background: none !important;
    font-size: 15px !important;
    text-transform: capitalize !important;
    font-weight: 400 !important;
    font-family: 'Sk-Modernist' !important;
    height: 35px;
    padding: 0 !important;
    box-shadow: none !important;
}

.e-business-card {
    max-width: 100%;
    margin: auto;
    margin-top: 20px;
    border-radius: 8px !important;
    box-shadow: none !important;
}

.e-business-content {
    background-image: url('assets/e-business-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 15px 15px !important;
    color: white;
    /* Text color on top of the background image */
}

.e-business-content .rating-icon,
.e-business-content .delivery-icon {
    margin-top: 2px;
    margin-right: 7px;
}

.e-business-card .divider {
    background: rgba(255, 255, 255, 0.7);
}

/* Profile Section Ends Here */

/* Profile Freelancer Section Starts Here */

/* Profile Freelancer Section Ends Here */
.pro-details-accordion {
    margin: 0px !important;
    border-top: none !important;
}

.pro-details-accordion .MuiAccordionSummary-root {
    border-bottom: none;
    padding: 0;
}

.pro-details-accordion .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
    min-height: 50px;
}

.pro-details-accordion .MuiAccordionDetails-root {
    color: #757575;
    font-family: 'Gotham' !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border-bottom: none;
    padding: 6px 0px !important;
    text-align: start;
}

.pro-details-accordion .MuiAccordion-root {
    box-shadow: none !important;
}

.pro-details-accordion .MuiAccordion-root.Mui-expanded:last-of-type {
    border-top: none !important;
}

/* Freelancer Bio Section Starts Here */
.product-accordion {
    margin: 15px 0px !important;
    border-top: none !important;
}

.product-accordion .MuiAccordionSummary-root {
    border-bottom: 1px solid #dedede;
    padding: 0;
}

.product-accordion .MuiAccordionDetails-root {
    color: #757575;
    font-family: 'Gotham' !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border-bottom: none;
    padding: 6px 0px !important;
    text-align: start;
}

.product-accordion .MuiAccordion-root {
    box-shadow: none !important;
}

.product-accordion .MuiAccordion-root.Mui-expanded:last-of-type {
    border-top: none !important;
}

/* Freelancer Bio Section Ends Here */

/* FL Freelance section starts here */

.b2b-business .MuiChip-root {
    border-radius: 4px !important;
    background: #f0f0f0 !important;
    color: #1843a0;
    font-family: 'Gotham' !important;
    font-size: 13px;
}

.freelance-testimonial-card {
    width: 100%;
    margin: auto;
    border-radius: 8px !important;
    box-shadow: none !important;
}

.freelance-testimonial-content {
    background-image: url('assets/JobSeeker-List-Header764x300px.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* padding: 30px 0px 25px 25px !important; */
    padding: 0px !important;
    color: white;
    height: 172px;
    position: relative;
}

.freelance-testimonial-content-tint {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-color: #7165eb;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.85;
}

.freelance-testimonial-text {
    position: relative;
    top: 40px;
    left: 20px;
}

.freelance-testimonial-web-card {
    width: 100%;
    margin: auto;
    border-radius: 8px !important;
    box-shadow: none !important;
}

.freelance-testimonial-web-content {
    background-image: url('assets/JobSeeker-List-Header764x300px.png');
    /* background-size: contain; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0px !important;
    color: white;
    height: 230px;
    position: relative;
}

.freelance-testimonial-web-content-tint {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-color: #7165eb;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.85;
}

/* FL Freelance section end here */

/* FL Freelance Category Section Starts Here */
.freelance-category-card {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    padding: 40px 0px 25px 20px !important;
    color: white;
    height: 172px;
}

.freelance-category-content {
    background-image: url('assets/freelancer-category-bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0px !important;
    color: white;
    height: 180px;
}

.freelance-category-text {
    position: relative;
    top: 40px;
    left: 20px;
    font-size: 16px !important;
}

.freelance-category-button {
    position: relative;
    top: 42px;
    left: 20px;
}

.freelance-list-item {
    margin-bottom: 16px;
    padding: 0px !important;
}

.freelance-list-item .card {
    /* display: flex; */
    /* flex-direction: row !important; */
    width: 100% !important;
    padding: 14px 14px 16px 14px !important;
    box-shadow: none !important;
    border-radius: 8px !important;
    border: 1px solid #e3e3e3 !important;
}

.freelance-list-item .content {
    width: 69%;
    margin-left: 15px;
}

.freelance-list-item .category-media {
    width: 84px;
    height: 84px;
    border-radius: 8px;
}

/* FL Freelance Category Section Ends Here */

/* B2B Detail Section Starts Here */

.btn-enquire-now {
    color: #864ffd !important;
    font-size: 17px !important;
    text-transform: none !important;
    text-decoration: underline !important;
    cursor: pointer;
    font-weight: 600 !important;
    font-family: 'Sk-Modernist' !important;
    padding: 0px !important;
}

.b2b-detail-form {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px !important;
}

.b2b-detail-card {
    margin-bottom: 16px;
    padding: 0px !important;
}

.b2b-detail-card .card {
    /* display: flex; */
    /* flex-direction: row !important; */
    width: 100% !important;
    padding: 0px !important;
    box-shadow: none !important;
    border-radius: 8px !important;
    border: none !important;
}

.b2b-detail-card .content {
    width: 100%;
    margin-left: 15px;
}

.b2b-detail-card .category-media {
    width: 74px;
    height: 74px;
    border-radius: 6px;
}

.b2b-detail-card .MuiChip-root {
    border-radius: 4px !important;
    background: #00b257 !important;
    color: #ffffff !important;
    font-family: 'Gotham' !important;
    font-weight: 400 !important;
    font-size: 13px;
}

.b2b-detail-web-card {
    margin-bottom: 16px;
    padding: 0px !important;
}

.b2b-detail-web-card .card {
    /* display: flex; */
    /* flex-direction: row !important; */
    width: 100% !important;
    padding: 0px !important;
    box-shadow: none !important;
    border-radius: 8px !important;
    border: none !important;
}

.b2b-detail-web-card .content {
    width: 100%;
    margin-left: 15px;
}

.b2b-detail-web-card .category-media {
    width: 136px !important;
    height: 130px !important;
    border-radius: 6px;
}

.b2b-detail-web-card .web-card-gap {
    gap: 100px;
}

.catalog-carousel .carousel .control-dots .dot {
    background: #1843a0;
}

.catalog-carousel .carousel .slider-wrapper {
    /* margin-bottom: 40px; */
}

.top-products .MuiChip-root {
    border-radius: 4px !important;
    background: #61a3f2 !important;
    color: #ffffff !important;
    font-family: 'Gotham' !important;
    font-weight: 400 !important;
    font-size: 13px;
    padding: 18px 10px !important;
}

.top-products .MuiStack-root {
    flex-wrap: nowrap;
    overflow-x: auto;
}

.b2b-detail-tabs .Mui-selected {
    color: #864ffd !important;
}

.b2b-detail-tabs .MuiTabs-indicator {
    background-color: #864ffd !important;
}

.b2b-detail-tabs .MuiButtonBase-root {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
}

.b2b-detail-tabs .b2b-detail-tabs-title {
    margin-right: 22px !important;
}

.b2b-detail-tabs .MuiBadge-badge {
    background-color: #eb5757 !important;
    font-size: 13px;
}

.rating-review-card {
    margin-bottom: 16px;
    margin-top: 16px;
    padding: 0px !important;
}

.rating-review-card .card {
    /* display: flex; */
    /* flex-direction: row !important; */
    width: 100% !important;
    padding: 20px !important;
    box-shadow: none !important;
    border-radius: 8px !important;
    background-color: #f6f6f6 !important;
    border: 1px solid #e3e3e3 !important;
}

.submit-rating-btn {
    border-radius: 6px !important;
    background-color: #864ffd !important;
    color: #fff !important;
    font-size: 15px !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    font-family: 'Sk-Modernist' !important;
    height: 44px;
    padding: 12px 15px !important;
}

.send-req-btn {
    border-radius: 6px !important;
    border: 1px solid #e3e3e3 !important;
    background: #fff !important;
    color: #000000 !important;
    font-size: 15px !important;
    text-transform: none !important;
    font-weight: 400 !important;
    font-family: 'Sk-Modernist' !important;
    box-shadow: none !important;
}

/* B2B Detail Section Ends Here */

/* Freelancer Detail Section Ends Here  */

.freelancer-detail-form {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px !important;
}

.freelancer-detail-card {
    margin-bottom: 16px;
    margin-top: 14px;
    padding: 0px !important;
}

.freelancer-detail-card .card {
    /* display: flex; */
    /* flex-direction: row !important; */
    width: 100% !important;
    padding: 0px !important;
    box-shadow: none !important;
    border-radius: 8px !important;
    border: none !important;
}

.freelancer-detail-card .content {
    width: 100%;
    margin-left: 15px;
}

.freelancer-detail-card .category-media {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: 2px 1px 18px 3px #00000014;
}

.freelancer-detail-web-card {
    margin-bottom: 30px;
    padding: 0px !important;
}

.freelancer-detail-web-card .card {
    /* display: flex; */
    /* flex-direction: row !important; */
    width: 100% !important;
    padding: 0px !important;
    box-shadow: none !important;
    border-radius: 8px !important;
    border: none !important;
}

.freelancer-detail-web-card .content {
    width: 100%;
    margin-left: 15px;
}

.freelancer-detail-web-card .category-media {
    width: 80px !important;
    height: 80px !important;
    border-radius: 50%;
}

.freelancer-detail-web-card .web-card-gap {
    gap: 100px;
}

.seller-info {
    width: 100%;
}

.seller-info-title {
    width: 45%;
}

.seller-info-detail {
    width: 55%;
}

.freelancer-detail-accordion {
    margin: 15px -16px !important;
    border-top: 2px solid #dedede;
}

.freelancer-detail-accordion .MuiAccordion-root {
    box-shadow:
        inset 2px 0px 1px -1px #fff,
        0px 1px 1px 0px #dedede,
        0px 1px 3px 0px #fff !important;
}

.freelancer-detail-accordion .MuiAccordion-root:last-of-type,
.freelancer-detail-accordion .MuiAccordion-root:first-of-type {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.freelancer-detail-accordion .MuiAccordion-root.Mui-expanded {
    margin: 0px !important;
}

.freelancer-detail-accordion .MuiAccordionSummary-content .MuiListItemText-primary {
    color: #416ad4 !important;
    font-family: 'Gotham' !important;
    font-size: 15px;
    margin-bottom: 0px !important;
    margin-top: 5px;
}

.freelancer-detail-accordion .MuiAccordionSummary-content.Mui-expanded {
    /* margin: 12px 0 0 0; */
    margin: 0px;
}

.freelancer-detail-accordion .MuiAccordionSummary-root.Mui-expanded {
    min-height: 45px;
}

.MuiAccordion-root.Mui-expanded:last-of-type {
    border-top: none !important;
}

.freelancer-detail-accordion .MuiAccordionDetails-root {
    color: #757575;
    font-family: 'Gotham' !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border-bottom: 1px solid #dedede;
    padding: 0px 26px 16px 36px;
    text-align: start;
}

.freelancer-detail-accordion .MuiListItemIcon-root {
    min-width: 0px !important;
}

.freelancer-detail-accordion .MuiListItemText-root {
    margin-top: 1px;
    color: #393939;
    font-family: 'Sk-Modernist' !important;
    font-size: 15px;
}

.feedback-chips .MuiChip-root {
    border-radius: 4px !important;
    border: 1px solid #864ffd;
    background: #fff;
    color: #864ffd;
    font-family: 'Gotham' !important;
    font-weight: 400 !important;
    font-size: 13px;
    padding: 17px 12px !important;
}

/* .feedback-chips .MuiChip-clickable {
  background-color: red !important;
  color: #fff !important;
} */

li.MuiListItem-root.review-list {
    padding: 20px 0px;
    border-bottom: 1px solid #e3e3e3;
}

.review-list .MuiListItemAvatar-root {
    margin-top: 0px !important;
    padding-top: 5px;
}

/* Freelancer Detail Section Ends Here  */

/* B2C Section Starts Here */

.edit-profile-btn {
    color: #864ffd !important;
    background: none !important;
    font-size: 15px !important;
    text-transform: capitalize !important;
    font-weight: 400 !important;
    font-family: 'Sk-Modernist' !important;
    height: 35px;
    padding: 0 !important;
    box-shadow: none !important;
}

/* B2C Section Ends Here */

/* Subscription Sections Starts Here  */

.subscription-card {
    max-width: 100%;
    margin: auto;
    /* margin-top: 20px; */
    border-radius: 8px !important;
    box-shadow: none !important;
}

.subscription-content {
    background-image: url('assets/plan-banner.png');
    /* background-image: url('assets/plan-banner.svg'); */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0px !important;
    color: white;
    height: 176px;
}

.subscription-content .subscription-text {
    position: relative;
    top: 45px;
    left: 20px;
}

.subscription-web-card {
    width: 100%;
    margin: auto;
    /* margin-top: 20px; */
    border-radius: 8px !important;
    box-shadow: none !important;
}

.subscription-web-content {
    background-image: url('assets/plan-web-banner.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    padding: 120px 0px 25px 45px !important;
    color: white;
    height: 274px;
}

.basic-plan {
    margin-bottom: 16px;
    padding: 0px !important;
}

.basic-plan .card {
    /* display: flex; */
    /* flex-direction: row !important; */
    width: 100% !important;
    padding: 14px 14px 16px 14px !important;
    box-shadow: none !important;
    border-radius: 8px !important;
    border: 1px solid #61a3f2 !important;
}

.current-plan-btn {
    border-radius: 6px !important;
    background: #61a3f2 !important;
    color: #fff !important;
    font-size: 12px !important;
    text-transform: uppercase !important;
    font-weight: 400 !important;
    font-family: 'Gotham' !important;
    padding: 10px 10px 8px 10px !important;
    /* letter-spacing: 1px !important; */
}

.subscription-plan {
    margin-bottom: 16px;
    padding: 0px !important;
}

.subscription-plan .card {
    /* display: flex; */
    /* flex-direction: row !important; */
    width: 100% !important;
    padding: 22px 19px 22px 19px !important;
    box-shadow: none !important;
    border-radius: 8px !important;
    border: 1px solid #864ffd !important;
    position: relative;
}

.subscription-plan .card .star-vector-img {
    position: absolute;
    top: 0;
    right: 0;
}

.subcription-available-plans .plans-header .premium-card {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    width: 100%;
}

.subcription-available-plans .plans-header .free-card {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    width: 100%;
}

.subcription-available-plans .plans-content {
    border: 1px solid #e3e3e3 !important;
    border-radius: 6px !important;
}

.plans-content.MuiCardContent-root:last-child {
    padding-bottom: 10px !important;
}

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.cardsContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.cardsContainer .card {
    width: 47%;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px 15px 16px 16px;
    position: relative;
    cursor: pointer;
    border-color: #864ffd;
}

.checkIcon {
    position: absolute;
    top: 11px;
    right: 12px;
}

.annual-discount {
    font-family: 'Gotham' !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 11.48px;
    color: #00b257 !important;
    background-color: #f0f0f0;
    border-radius: 20px;
    padding: 7px 16px 5px;
    display: inline-block;
}

.btnSubscribe {
    margin-top: 24px;
    margin-bottom: 24px;
}

/* .premium-field {
  padding-left: 0px !important;
}

.free-field {
  padding-left: 0px !important;

} */
/* Subscription Sections Ends Here */

/* Subscriptionn Section Starts Here */
.subscription-banner-web-card {
    width: 100%;
    margin: auto;
    margin-top: 20px;
    border-radius: 24px !important;
    box-shadow: none !important;
}

.subscription-banner-web-content {
    /* background-image: url('assets/subscribe-banner.jpg'); */
    /* background-size: contain; */
    background: linear-gradient(90deg, #1843A0 0%, #864FFD 100%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;
    height: 220px;
    position: relative;
    /* padding-top: 25px !important; */

    /* padding-left: 80px !important; */
}

.subscription-banner-web-content::before {
    content: "";
    display: block;
    background-image: url('assets/subscription-line-left.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
}

.subscription-banner-web-content::after {
    content: "";
    display: block;
    background-image: url('assets/subscription-line-right.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    width: auto;
    height: 100%;
    position: absolute;
    top: 0px;
}

.subscription-banner-web-main-box {
    display: flex;
    gap: 80px;
    left: 6%;
    position: relative;
}

.subscription-banner-man {
    width: max-content;
    position: relative;

}

.subscription-banner-web-box {
    align-content: center;
}

.subscription-banner-web-box .subscription-banner-web-text {
    font-family: 'Sk-Modernist' !important;
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 47px !important;
    color: #f2f2f2 !important;
    margin-bottom: 22px !important;
}

.subscription-banner-web-box .sub-box {
    display: flex;
    align-items: center;
    gap: 18px;

}

.subscription-banner-web-box .sub-box .sub-inner-box {
    display: flex;
    align-items: center;
    gap: 9px;
    border-right: 1px solid #FFFFFF26;
    padding-right: 8px;
}

.subscription-banner-web-box .sub-box .sub-inner-box:last-child {
    border-right: none;
}

.subscription-banner-web-box .sub-box .sub-inner-box .subscription-banner-web-subtext {
    font-family: 'Gotham' !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
}

/* .subscription-banner-web-content-tint {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-color: #416ad4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.8;
} */

.subscription-banner-web-text {
    font-family: 'Sk-Modernist' !important;
    font-size: 30px !important;
    font-weight: 700 !important;
    line-height: 40px !important;
    text-align: left;
    color: #f2f2f2 !important;
    margin-bottom: 6px !important;
}

.subscription-banner-web-subtext {
    font-family: 'Sk-Modernist' !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    text-align: left;
    color: #fff !important;
}

/* .package-box:hover {
    background: linear-gradient(180deg, #864FFD 0%, #1843A0 100%);
    color: #fff !important;
    cursor: pointer;
    box-shadow: 0px 0px 30px 2px #00000005;

} 

.package-box:hover .inner-box .plan-type,
.package-box:hover .inner-box .plan-price,
.package-box:hover .inner-box .plan-info,
.package-box:hover .inner-box .plan-text,
.package-box:hover .inner-box .per-month-price,
.package-box:hover .inner-box .slashed-price,
.package-box:hover .package-list .check-icon.enabled,
.package-box:hover .inner-box .offer-ribbon,
.package-box:hover .package-list .list-text,
.package-box:hover .package-list .list-text-bold,
.package-box:hover .terms-box .caption {
    color: #fff !important;
} */

.package-box.essential-package-box {
    background: linear-gradient(180deg, #864FFD 0%, #1843A0 100%) !important;
    color: #fff !important;
    /* cursor: pointer; */
    box-shadow: 0px 0px 30px 2px #00000005 !important;

} 

.package-box.essential-package-box .inner-box .plan-type,
.package-box.essential-package-box .inner-box .plan-price,
.package-box.essential-package-box .inner-box .plan-info,
.package-box.essential-package-box .inner-box .plan-text,
.package-box.essential-package-box .inner-box .per-month-price,
.package-box.essential-package-box .inner-box .slashed-price,
.package-box.essential-package-box .package-list .check-icon.enabled,
.package-box.essential-package-box .inner-box .offer-ribbon,
.package-box.essential-package-box .package-list .list-text,
.package-box.essential-package-box .package-list .list-text-bold,
.package-box.essential-package-box .terms-box .caption {
    color: #fff !important;
}

.package-box {
    border: 1px solid #734EED;
    /* border-image-source: linear-gradient(180deg, #2145A8 0%, #734EED 100%); */
    border-radius: 24px;
    padding: 28px 20px;
    position: relative;
}

.package-box .inner-box {
    border-radius: 10px;
    color: #fff;
    margin-bottom: 25px;
    /* height: 110px; */
}

.package-box .inner-sub-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}


.free-now-ribbon {
    font-family: 'Sk-Modernist' !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    padding: 6px 11px;
    background: #00B257;
    border-radius: 19px;
}

.limited-time-ribbon {
    font-family: 'Sk-Modernist' !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    padding: 6px 11px;
    background: #EB4BB6;
    border-radius: 19px;
}

.inner-box .inner-sub-box .plan-type {
    font-family: 'Sk-Modernist' !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 21.6px !important;
    margin-bottom: 5px !important;
    color: #121212 !important;

}

.inner-box .plan-text {
    font-family: 'Sk-Modernist' !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 21.6px !important;
    color: #000 !important;
    margin-bottom: 20px;
}

.inner-box .per-month-price {
    font-family: 'Sk-Modernist' !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 28.8px !important;
    color: #121212 !important;
}

.inner-box .gst-span {
    font-family: 'Sk-Modernist' !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 16.8px !important;

}

.inner-box .per-month {
    font-family: 'Sk-Modernist' !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 14.4px !important;
}

.inner-box .slashed-price {
    font-family: 'Sk-Modernist' !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 16.39px !important;
    text-decoration: line-through !important;
    color: #000000 !important;
    margin-bottom: 30px;
}

.package-box .inner-box .annual-box {
    background-color: #4F4ACF;
    border-radius: 14px;
    padding: 19px 16px;
    text-align: center;
}

.annual-text {
    font-family: 'Sk-Modernist' !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 29px !important;
    color: #fff;
}

.strike-through {
    position: relative;
    display: inline-block;
    font-family: 'Sk-Modernist';
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 22px !important;
    text-transform: capitalize;
}

.strike-through::before {
    content: '';
    position: absolute;
    top: 72%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: red;
    transform: rotate(-9deg);
    transform-origin: 0 50%;
}

.now-text {
    font-family: 'Sk-Modernist';
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
}

.package-box .header-title {
    font-family: 'Sk-Modernist' !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 21.6px !important;
    margin-bottom: 18px;
    color: #1843a0 !important;
}

.package-box .package-list {
    list-style: none !important;
    padding-left: 0px;
}

.package-box .package-list li {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.package-box .package-list .list-text {
    font-family: 'Gotham' !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    color: #5f5f5f !important;
}

.package-box .divider {
    opacity: 1;
    border: 1px solid #c5c5c5;
    margin-bottom: 15px;
}

/* .terms-box {} */

.note-box {
    padding: 14px 25px;
    background-color: #d9d9d9;
    border-radius: 10px;
}

.note-box .note {
    color: #1843a0;
    font-family: Arimo Hebrew Subset Italic;
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    line-height: 14.95px;
    text-align: justified;
}

.benefits-services {
    padding-bottom: 50px;
}

.package-box .terms-box {
    width: 100%;
    justify-content: center;
    display: flex;
}

.package-box .terms-box .caption {
    font-family: 'Sk-Modernist' !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 16.8px !important;
    text-align: left !important;
    color: #3347B7 !important;
    width: 100%;
    text-align: center !important;
    position: absolute;
    bottom: 20px;
    border-top: 1px solid #EEEEEE;
    padding-top: 10px;
}

.package-box .header-subtitle {
    font-family: 'Gotham' !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    color: #5f5f5f !important;
    margin-bottom: 10px;
}

.essential-inner-box.inner-box {
    background: linear-gradient(138deg,
            rgba(134, 79, 253, 1) 28%,
            rgba(55, 107, 194, 1) 49%,
            rgba(86, 148, 229, 1) 88%);
    height: 110px;
}

.blur-package-box {
    position: relative;
    /* padding: 20px; */
    border-radius: 20px;
    background-color: #d9d9d966 !important;
    box-shadow: 0px 0px 10px 0px #000000bf;
}

.blur-package-box .package-box.blur-content:hover {
    background: none !important;
}

.blur-content {
    filter: blur(5px);
    opacity: 0.5;
    background: none !important;
}

.top-right-ribbon {
    position: absolute;
    top: 20px;
    right: 20px;
    /* transform: translate(-50%, -50%); */
    padding: 6px 14px;
    background: linear-gradient(138deg, rgba(24, 67, 160, 1) 30%, rgba(80, 73, 208, 1) 63%, rgba(110, 76, 232, 1) 88%);
    border-radius: 20px;
    color: white;
    text-align: center;
    box-shadow: 0px 6px 19px 5px #00000057;
    z-index: 10;
    font-family: 'Sk-Modernist';
    width: max-content;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
}

.center-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 15px 30px;
    background: linear-gradient(138deg, rgba(24, 67, 160, 1) 30%, rgba(80, 73, 208, 1) 63%, rgba(110, 76, 232, 1) 88%);
    border-radius: 20px;
    color: white;
    text-align: center;
    box-shadow: 0px 6px 19px 5px #00000057;
    z-index: 10;
    font-family: 'Sk-Modernist';
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    width: 80%;
}

.message-text {
    font-size: 16px;
    font-weight: bold;
}

/* Subscriptionn Section Ends HEre */

/* New Subcription Section Starts Here */
.subscription-custom-card {
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #9d9d9d;
    padding: 13px;
}

.subscription-custom-card .card-content {
    padding: 0px !important;
}

.subscription-custom-card .card-content .inner-card {
    background: radial-gradient(119.68% 122.28% at 100% 50%,
            rgba(24, 67, 160, 0) 0%,
            rgba(24, 67, 160, 0.8) 100%);
    padding: 12px 21px;
}

/* New Subcription Section Ends Here */



/* Product Tools Section Starts Here  */
.product-tools-card {
    max-width: 100%;
    margin: auto;
    margin-top: 8px;
    border-radius: 8px !important;
    box-shadow: none !important;
}

/* .product-tools-content {
	background-image: url('assets/product-tools-bg.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	padding: 34px 0px 25px 20px !important;
	color: white;
	height: 172px;
} */

.product-tools-web-card {
    width: 100%;
    margin: auto;
    margin-top: 8px;
    border-radius: 8px !important;
    box-shadow: none !important;
}

/* .product-tools-web-content {
	background-image: url('assets/product-tools-web-bg.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	padding: 80px 0px 25px 38px !important;
	color: white;
	height: 230px;
} */

.product-tools-list-item {
    margin-bottom: 16px;
    padding: 0px !important;
}

.product-tools-list-item .card {
    /* display: flex; */
    /* flex-direction: row !important; */
    width: 100% !important;
    padding: 14px 14px 16px 14px !important;
    box-shadow: none !important;
    border-radius: 8px !important;
    border: 1px solid #e3e3e3 !important;
}

.product-tools-list-item .content {
    width: 69%;
    margin-left: 15px;
}

.product-tools-list-item .category-media {
    width: 110px;
    height: 84px;
    border-radius: 8px;
}

.view-details-btn {
    border-radius: 6px !important;
    background: #fff !important;
    color: #61a3f2 !important;
    border: 1px solid #61a3f2 !important;
    padding: 9px 16px !important;
    letter-spacing: 1px !important;
    text-transform: capitalize !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    font-family: 'Sk-Modernist' !important;
    height: 41px !important;
}

.subscribe-btn {
    border-radius: 6px !important;
    background: #864ffd !important;
    color: #fff !important;
    font-size: 13px !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    font-family: 'Sk-Modernist' !important;
    padding: 9px 16px !important;
    letter-spacing: 1px !important;
    height: 41px !important;
}

.product-filter-section .MuiChip-root {
    border-radius: 4px !important;
    background: #fff;
    color: #1843a0;
    font-family: 'Gotham' !important;
    font-size: 13px;
    border: 1px solid #1843a0;
    height: 36px;
}

.product-filter-slider::-webkit-scrollbar {
    width: 8px;
    /* Width of the scrollbar */
    height: 5px;
}

/* Product Tools Section Ends Here  */

/* Elynker Services Section Starts Here */

.elynker-services-card {
    max-width: 100%;
    margin: auto;
    margin-top: 8px;
    border-radius: 8px !important;
    box-shadow: none !important;
}

.elynker-services-content {
    background-image: url('assets/Tools-List-Header764x300px.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* padding: 34px 0px 25px 20px !important; */
    color: white;
    height: 172px;
    position: relative;
}

.elynker-services-tint {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-color: #7165eb;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.85;
}

.services-btn {
    border-radius: 6px !important;
    background: #864ffd !important;
    color: #fff !important;
    font-size: 15px !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    font-family: 'Sk-Modernist' !important;
    width: 47% !important;
    padding: 11px 16px 12px 16px !important;
    letter-spacing: 1px !important;
}

/* Elynker Services Section Ends Here */

/* Active Requiremenets Section Starts Here */

.active-requirements-card {
    max-width: 100%;
    margin: auto;
    margin-top: 20px;
    border-radius: 8px !important;
    box-shadow: none !important;
}

.active-requirements-content {
    background-image: url('assets/active-requirements-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 25px 20px !important;
    color: white;
    /* Text color on top of the background image */
}

.active-req-list-item {
    margin-bottom: 16px;
    padding: 0px !important;
}

.active-req-list-item .card {
    display: flex;
    flex-direction: row !important;
    width: 100% !important;
    padding: 12px !important;
    box-shadow: none !important;
    border-radius: 8px !important;
    border: 1px solid #e3e3e3 !important;
}

.active-req-list-item .media {
    width: 120px;
    height: 110px;
    border-radius: 8px !important;
}

.active-req-list-item .media-img {
    border-radius: 8px !important;
}

.active-req-list-item .content {
    flex: 1;
    padding: 0px 0px 0px 10px !important;
}

.active-req-list-item .actions {
    display: flex;
    gap: 8px;
    margin-top: 8px;
}

.active-req-list-item .actions .cat-edit-icon {
    padding: 0px !important;
}

.active-req-list-item .actions .cat-delete-icon {
    padding: 0px !important;
}

.active-req-list-item.active-req {
    align-items: stretch !important;
}

.text-hold {
    color: #4176ff !important;
}

.text-fulfilled {
    color: #00b257 !important;
}

.text-pending {
    color: #ffa700 !important;
}

.text-cancelled {
    color: #f05d23 !important;
}

.skip-btn {
    background: #fafafa !important;
    border: 1px solid #1843a0 !important;
    color: #1843a0 !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    padding: 12px 20px !important;
    border-radius: 6px !important;
    text-transform: capitalize !important;
    width: 46%;
    /* max-height: 43px; */
}

.save-btn {
    background: #1843a0 !important;
    color: #fff !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    padding: 8px 15px !important;
    border-radius: 6px !important;
    padding: 12px 20px !important;
    text-transform: capitalize !important;
    width: 46%;
}

/* Active Requiremenets Section Ends Here */

/* Job Application Section Starts Here */

.job-list-item {
    margin-bottom: 16px;
    padding: 0px !important;
}

.job-list-item .card {
    display: flex;
    flex-direction: row !important;
    width: 100% !important;
    padding: 16px 20px !important;
    box-shadow: none !important;
    border-radius: 8px !important;
    border: 1px solid #e3e3e3 !important;
}

.job-list-item .content {
    flex: 1;
    padding: 0px !important;
}

.job-list-item .actions {
    display: flex;
    gap: 8px;
    margin-top: 8px;
}

.job-list-item .actions .cat-edit-icon {
    padding: 0px !important;
}

.job-list-item .actions .cat-delete-icon {
    padding: 0px !important;
}

/* Job Application Section Ends Here */

/* Job Listing Section Starts Here */
.job-listing-item {
    margin-bottom: 16px;
    padding: 0px !important;
}

.job-listing-item .card {
    /* display: flex; */
    /* flex-direction: row !important; */
    width: 100% !important;
    padding: 14px 14px 16px 14px !important;
    box-shadow: none !important;
    border-radius: 8px !important;
    border: 1px solid #e3e3e3 !important;
}

.job-listing-item .content {
    width: 100%;
    margin-left: 15px;
}

.job-listing-item .category-media {
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
}

.apply-btn {
    border-radius: 6px !important;
    background: #864ffd !important;
    color: #fff !important;
    font-size: 13px !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    font-family: 'Sk-Modernist' !important;
    padding: 9px 25px !important;
    letter-spacing: 1px !important;
}

.job-listing-card {
    max-width: 100%;
    margin: auto;
    /* margin-top: 20px; */
    border-radius: 8px !important;
    box-shadow: none !important;
}

.job-listing-content {
    background-image: url('assets/JobsNearyou-List-Header764x300px.png');
    /* background-size: contain; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0px !important;
    color: white;
    height: 180px;
    position: relative;
}

.job-listing-content-tint {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-color: #7165eb;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.85;
}

.job-listing-text {
    position: relative;
    top: 40px;
    left: 20px;
    font-size: 16px !important;
}

.job-listing-button {
    position: relative;
    top: 42px;
    left: 20px;
}

.job-listing-web-card {
    width: 100%;
    margin: auto;
    /* margin-top: 20px; */
    border-radius: 8px !important;
    box-shadow: none !important;
}

.job-listing-web-content {
    background-image: url('assets/JobsNearyou-List-Header2340x400px.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0px !important;
    color: white;
    height: 207px;
    position: relative;
}

.job-listing-web-content-tint {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-color: #7165eb;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.85;
}

.job-list-web-item {
    margin-bottom: 16px;
    padding: 0px !important;
}

.job-list-web-item .card {
    /* display: flex; */
    /* flex-direction: row !important; */
    width: 100% !important;
    padding: 20px !important;
    box-shadow: none !important;
    border-radius: 8px !important;
    border: 1px solid #e3e3e3 !important;
}

.job-list-web-item .content {
    width: 100%;
    margin-left: 15px;
}

.job-list-web-item .category-media {
    width: 93px;
    height: 89px;
    border-radius: 8px !important;
}

.job-list-web-item .subcategory-media {
    border-radius: 50%;
    height: 40px !important;
    width: 40px !important;
}

/* Job Listing Section Starts Here */

/* Product Catalog Section Starts Here */
.app-container {
    /* max-width: 600px; */
    margin: 0 auto;
    margin-top: 30px;
    padding: 0px !important;
}

.header {
    text-align: center;
    margin-bottom: 10px;
}

.divider-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.product-divider {
    flex-grow: 1;
    height: 12px;
    background: repeating-linear-gradient(113deg,
            #dde2ee,
            #dde2ee 10px,
            transparent 13px,
            transparent 15px);
    margin-right: 25px;
    margin-top: 4px;
}

.product-table-bordered td,
.product-table-bordered th {
    border: 1px solid #d5d0e1;
}

.product-table-bordered th {
    background-color: rgba(134, 79, 253, 0.1);
    text-align: center;
    font-size: 20px;
    font-family: 'Sk-Modernist';
    font-weight: 700;
    color: #000;
}

.product-table-bordered td {
    font-family: 'Gotham';
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
}

.MuiListItem-root.fixture-list-item {
    padding: 0px !important;
}

.fixture-main-list {
    border: 1px solid #d5d0e1;
    border-radius: 6px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.fixture-list-item {
    border-bottom: 1px solid #d5d0e1;
}

.fixture-card {
    display: flex;
    margin-bottom: 0px;

    border-radius: 0px;
    overflow: hidden;
    box-shadow: none !important;
    width: 100%;
    padding: 23px 19px;
}

.fixture-image {
    width: 105px;
    height: 95px;
}

.fixture-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px !important;
    padding-left: 20px !important;
}

.fixtures-footer {
    margin-top: 26px !important;
    margin-bottom: 26px !important;
    text-align: center;
}

/* Product Catalog Section Ends Here */

/* Product Catalog List Section Starts Here */
.product-catalog-list {
    width: 100%;
    max-width: 780px;
    margin: 0 auto !important;
    background-color: #fff;
}

.product-catalog-list .paper {
    padding: 16px;
    margin: auto;
    max-width: 780px;
    box-shadow: none;
}

.product-catalog-list .media {
    width: 350px;
    height: 274px;
}

.product-catalog-list .content {
    border: 2px solid #dcdcdc;
    padding: 20px 32px;
    position: relative;
    min-height: 275px;
    height: auto;
}

.product-catalog-list .price-btn {
    background-color: #864ffd;
    color: #fff;
    font-size: 20px;
    font-family: 'Sk-Modernist' !important;
    padding: 5px 20px;
    margin-bottom: 10px;
    bottom: 5px;
    position: absolute;
}

.download-btn {
    color: #864ffd !important;
    font-size: 18px !important;
    font-family: 'Sk-Modernist' !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    padding: 12px 25px !important;
    background-color: #fff !important;
    border: 1px solid #864ffd !important;
    border-radius: 4px !important;
    box-shadow: none;
}

/* Product Catalog List Section Ends Here  */

/* Payment Details Section Starts Here */
.payment-details-table {
    box-shadow: none !important;
}

.payment-details-table .payment-details-heading {
    background: #f0f0f0;
    margin-left: -16px;
    margin-right: -16px;
    padding: 8px 16px;
}

.payment-details-table .MuiTableCell-root {
    padding: 6px 0px;
}

.payment-details-table .MuiTableFooter-root .MuiTableCell-root {
    padding: 12px 0px;
}



.payment-details-table .MuiTableBody-root .MuiTableCell-root {
    border-bottom: none !important;
}

.payment-details-table .MuiTableBody-root th.MuiTableCell-root {
    font-size: 16px;
    font-family: 'Sk-Modernist' !important;
    color: #727272;
    font-weight: 400;
    line-height: 20px;
}

.payment-details-table .MuiTableBody-root td.MuiTableCell-root {
    font-size: 16px;
    font-family: 'Sk-Modernist' !important;
    color: #393939;
    font-weight: 700;
    line-height: 19.2px;
}

.payment-details-table .MuiTableFooter-root th.MuiTableCell-root {
    font-size: 16px;
    font-family: 'Sk-Modernist' !important;
    color: #1843a0;
    font-weight: 400;
}

.payment-details-table .MuiTableFooter-root td.MuiTableCell-root {
    font-size: 16px;
    font-family: 'Sk-Modernist' !important;
    color: #1843a0;
    font-weight: 700;
}

.payment-details-table .MuiTableFooter-root {
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
}

.complete-payment {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    text-align: center;
}

.btn-complete-payment {
    padding: 16px !important;
    background: #1843a0 !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    display: block !important;
    letter-spacing: 5px !important;
}


.addon-accordion-container {
    width: 100%;
    background: transparent;
    margin: 0 auto;
}

.addon-accordion-container .accordion.Mui-expanded {
    margin-top: 0px !important;
}

.addon-accordion-container .accordion-title {
    margin-bottom: 17px;
    color: #000;
    font-family: 'Sk-Modernist' !important;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
}

.addon-accordion-container .accordion-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    background-color: transparent;
}

.addon-accordion-container .accordion-summary.Mui-expanded {
    min-height: 0px !important;
    height: 48px !important;
}

.addon-accordion-container .MuiAccordion-root.Mui-expanded:last-of-type {
    border-top: 1px solid #DEDEDE !important;
}

.addon-accordion-container .accordion-header {
    background-color: transparent;
    font-family: 'Sk-Modernist' !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #727272;
}

.accordion-header-container {
    display: flex;
    align-items: center;
}

.expand-icon {
    margin-right: 6px;
    /* Space between icon and title */
    color: #727272;
}

.addon-accordion-container .accordion-add {
    font-family: 'Gotham' !important;
    font-size: 15px;
    font-weight: 400;
    line-height: 14.36px;
    text-align: left;
    color: #1843A0;
}

.addon-accordion-container .accordion-details {
    background-color: transparent;
    padding: 0;
    padding-left: 30px;
}

.addon-accordion-container .accordion-details p {
    font-family: 'Gotham' !important;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    color: #727272;
}

.addon-accordion-container .accordion {
    border: none;
    box-shadow: none;
    border-top: 1px solid #DEDEDE !important;
}

.addon-accordion-container .accordion-summary:hover {
    background-color: transparent;
}

.complete-payment .payment-price {
    font-family: 'Sk-Modernist' !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    color: #fff !important;
    margin-bottom: 5px !important;
    text-transform: none !important;
}

.complete-payment .payment-text {
    font-family: 'Sk-Modernist' !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 14.4px !important;
    color: #fff !important;
}

/* Payment Details Section Ends Here */

/* Web Payment Details Section Starts Here */
/* .web-payment-details {
    
} */

.web-payment-details .plan-details {
    height: 100%;
    border: 1px solid #E2E2E2;
    padding: 24px 30px 35px 30px;
    background-color: transparent;
    border-radius: 8px;
}

.web-payment-details .plan-details .title {
    font-family: 'Sk-Modernist' !important;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    color: #000;
    margin-bottom: 15px;

}

.web-payment-details .plan-details .subtitle {
    font-family: 'Gotham' !important;
    font-size: 15px;
    font-weight: 400;
    line-height: 14.36px;
    margin-bottom: 25px;
    color: #727272;
}

.web-payment-details .plan-details .plan-type {
    font-family: 'Gotham' !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 15.31px !important;
    color: #000 !important;
    margin-bottom: 4px !important;
}

.web-payment-details .plan-details .plan-price {
    font-family: 'Sk-Modernist' !important;
    font-size: 22px !important;
    font-weight: 700 !important;
    line-height: 26.4px !important;
    text-align: left !important;
}

.web-payment-details .plan-details .now-text {
    font-family: 'Sk-Modernist' !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 16.8px !important;
    color: #000 !important;
}

.web-payment-details .plan-details .free-text {
    font-family: 'Sk-Modernist' !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 16.8px !important;
    color: #000 !important;
}

.web-payment-details .plan-details .offer-ribbon {
    font-family: 'Sk-Modernist' !important;
    font-size: 10px !important;
    font-weight: 700 !important;
    line-height: 12px !important;
    text-transform: uppercase !important;
    color: #fff !important;
    background-color: #F04D23 !important;
    padding: 7px 8px !important;
    border-radius: 56px !important;
}


.web-payment-details .summary-details {
    height: 100%;
    border: 1px solid #E2E2E2;
    padding: 24px 30px;
    background-color: transparent;
    border-radius: 8px;
}

.web-payment-details .summary-details .title {
    font-family: 'Sk-Modernist' !important;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    color: #000;
    margin-bottom: 15px;
}


.web-payment-details .addon-details {
    height: 100%;
    border: 1px solid #E2E2E2;
    padding: 24px 30px;
    background-color: transparent;
    border-radius: 8px;
}

.web-payment-details .addon-details .title {
    font-family: 'Sk-Modernist' !important;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    color: #000;
    margin-bottom: 15px;
}

.web-payment-details .addon-details .pricing-table-container {
    box-shadow: none !important;
}

.web-payment-details .addon-details .pricing-table .MuiTableHead-root .MuiTableCell-root.thead-none {
    border: none !important;
    background: white !important;
    padding: 0px !important;
}

.web-payment-details .addon-details .pricing-table .MuiTableHead-root .thead-plan {
    background-color: #1843A0 !important;
    color: white !important;
    font-weight: bold !important;
    text-align: center !important;
    padding: 9px !important;
    font-family: 'Sk-Modernist' !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
}

.web-payment-details .addon-details .pricing-table .MuiTableBody-root {
    border: 1px solid #e0e0e0;
    border-radius: 6px !important;
}

.web-payment-details .addon-details .pricing-table .MuiTableBody-root .MuiTableCell-root {
    border: 1px solid #e0e0e0 !important;
    font-family: 'Sk-Modernist' !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    text-align: center !important;
    padding: 9px 20px !important;
    color: #727272 !important;
}

.web-payment-details .addon-details .pricing-table .MuiTableBody-root .first-cell {
    background-color: #FFFFFF;
}

.web-payment-details .addon-details .pricing-table .MuiTableBody-root .second-cell {
    background-color: #FAF8F8;
}





.web-payment-details .payment-details {
    height: 100%;
    border: 1px solid #E2E2E2;
    padding: 24px 30px;
    background-color: transparent;
    border-radius: 8px;
}

.web-payment-details .payment-details .title {
    font-family: 'Sk-Modernist' !important;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    color: #000;
    margin-bottom: 15px;
}




/* Web Payment Details Section Ends Here */

/* Web Login Sections Starts Here  */
.bgImage {
    background-image: url('assets/login-bg-admin.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 100vh;
    width: 100%;
}

.bgMain {
    background-color: #ffffff;
    /* your desired background color */
}

.logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Web Login Sections Ends Here  */

/* Web Onboarding Section Starts Here */

.btn-web-back {
    border-radius: 6px !important;
    border: 1px solid #d4d4d4 !important;
    background: #fff !important;
    color: #555050 !important;
    font-size: 15px !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    font-family: 'Sk-Modernist' !important;
    height: 46px !important;
    padding: 15px 25px 17px 25px !important;
}

.btn-web-next {
    border-radius: 6px !important;
    background: #1843a0 !important;
    color: #fff !important;
    font-size: 15px !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    font-family: 'Sk-Modernist' !important;
    height: 46px !important;
    padding: 15px 25px 17px 25px !important;
}

/* Web Onboarding Section Ends Here */

/* Modal section starts here */

.modal-main .MuiDialog-paper {
    border-radius: 18px !important;
}

.modal-body label {
    font-size: 15px;
    color: #363636;
    font-weight: 500;
}

.modal-header {
    padding-bottom: 0px;
}

.modal-footer {
    padding: 20px 24px !important;
}

.btn-cancel {
    background: #fafafa !important;
    border: 1px solid #e4e4e4 !important;
    color: #864ffd !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    padding: 8px 20px !important;
    border-radius: 6px !important;
    text-transform: capitalize !important;
    max-height: 43px;
}

.btn-save {
    background: #864ffd !important;
    color: #fff !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    padding: 5px 15px !important;
    border-radius: 6px !important;
    padding: 8px 20px !important;
    text-transform: capitalize !important;
}

.custom-border-header {
    position: relative !important;
    font-size: 20px !important;
    font-weight: bold !important;
    font-family: 'Sk-Modernist' !important;
}

.MuiDialog-paper {
    border-radius: 14px !important;
}

.custom-border-header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 5px;
    background: linear-gradient(to right,
            #1843a0 33%,
            #864ffd 33% 66%,
            #61a3f2 66% 100%);
    z-index: 1 !important;
}

.MuiDialogContent-root {
    padding: 20px 24px !important;
}

.MuiDialogActions-root {
    padding: 10px 24px 10px 24px !important;
}

/* Modal section ends here */

/* Terms & Conditions Section Starts Here */

.terms-list .MuiListItem-root {
    padding-left: 0px !important;
    font-size: 16px;
    font-weight: 700;
}

.terms-list .terms-heading.MuiTypography-root {
    font-weight: 700;
}

.terms-description.MuiTypography-root {
    color: #686868 !important;
    font-size: 14px;
    text-align: justify;
    font-family: 'Gotham' !important;
}

.terms-list .bullet-icon {
    min-width: 10px;
    color: #686868;
}

.terms-list .bullet-icon .MuiSvgIcon-root {
    width: 5px;
    height: 5px;
}

.terms-inner-list .MuiListItemText-root .MuiTypography-root {
    color: #686868 !important;
    font-size: 14px;
    font-family: 'Gotham' !important;
}

.privacy-list .bullet-icon {
    min-width: 15px;
    color: #000;
}

.privacy-list .bullet-icon .MuiSvgIcon-root {
    width: 7px;
    height: 7px;
}

.privacy-list .MuiListItemText-root .MuiTypography-root {
    color: #000 !important;
    font-size: 15px;
    font-family: 'Gotham' !important;
}

.privacy-list .terms-description.MuiTypography-root {
    padding-left: 15px;
}

/* Terms & Conditions Section Starts Here */

/* Job Posted Section Starts Here */

.job-posted-card {
    max-width: 100%;
    margin: auto;
    margin-top: 20px;
    border-radius: 8px !important;
    box-shadow: none !important;
}

.job-posted-content {
    background-image: url('assets/job-posted-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 25px 20px !important;
    color: white;
    /* Text color on top of the background image */
}

.job-popper-list {
    min-height: 35px !important;
}

/* Job Posted Section Starts Here */

/* Job Subscription Section Starts Here */

.job-subscription-card {
    max-width: 100%;
    margin: auto;
    margin-top: 20px;
    border-radius: 8px !important;
    box-shadow: none !important;
}

.job-subscription-content {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 25px 20px !important;
    color: white;
    /* Text color on top of the background image */
}

.autocomple-location .MuiAutocomplete-input {
    padding-right: 60px !important;
}

/* Job Subscription Section Starts Here */

/* Job Requirement Section Starts Here */

.MuiChipsInputCustom .MuiInputBase-formControl {
    padding: 10px !important;
    min-height: 48px !important;
}

.MuiChipsInputCustom .MuiOutlinedInput-input {
    padding: 0px !important;
}

/* Job Requirement Section Starts Here */

/* GBP Landing Section Starts Here */
.btn-subscribe {
    border-radius: 6px !important;
    text-transform: capitalize !important;
    background-color: #1843a0 !important;
    color: #fff !important;
    padding: 11px 0px !important;
    font-size: 15px !important;
    font-family: 'Sk-Modernist' !important;
    font-weight: 700 !important;
}

.btn-request-call {
    border-radius: 6px !important;
    text-transform: capitalize !important;
    color: #1843a0 !important;
    background: #fff !important;
    border: 1px solid #1843a0 !important;
    padding: 11px 0px !important;
    font-size: 15px !important;
    font-family: 'Sk-Modernist' !important;
    font-weight: 700 !important;
}

/* GBP Landing Section Starts Here */

/* Credit VAS Landing Section Starts Here */
.loan-card {
    max-width: 180px;
    border-radius: 8px !important;
    color: white;
    background-size: cover;
    background-position: center;
    position: relative;
}

.personal-loan-card-content {
    background-image: url('assets/personal-loan-banner.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 15px 20px !important;
    color: white;
}

.invoice-crediting-card-content {
    background-image: url('assets/invoice-crediting-banner.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 15px 20px !important;
    color: white;
}

.business-loan-card-contentcard-content {
    background-image: url('assets/business-loan-banner.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 15px 20px !important;
    color: white;
}

.business-loan-card-content {
    background-image: url('assets/business-loan-banner.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 15px 20px !important;
    color: white;
}

.short-term-card-content {
    background-image: url('assets/business-loan-banner.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 15px 20px !important;
    color: white;
}

.view-more-button {
    background: none !important;
    color: #fff !important;
    font-size: 14px !important;
    padding: 0px !important;
    box-shadow: none !important;
    text-transform: capitalize !important;
    font-family: 'Sk-Modernist' !important;
}

/* Credit VAS Landing Section Ends Here */

/* Manage Website Section Starts Here */
.btn-publish {
    border-radius: 6px !important;
    text-transform: capitalize !important;
    background-color: #1843a0 !important;
    color: #fff !important;
    padding: 11px 25px !important;
    font-size: 15px !important;
    font-family: 'Sk-Modernist' !important;
    font-weight: 700 !important;
    border: 1px solid transparent !important;
}

.btn-preview {
    border-radius: 6px !important;
    text-transform: capitalize !important;
    color: #1843a0 !important;
    background: #fff !important;
    border: 1px solid #1843a0 !important;
    padding: 11px 25px !important;
    font-size: 15px !important;
    font-family: 'Sk-Modernist' !important;
    font-weight: 700 !important;
}

.website-testimonial-card {
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    height: 100%;
}

.website-catalog-card {
    box-shadow: none !important;
}

.website-catalog-content {
    border-bottom: 1px solid #dedede !important;
}

/* Manage Website Section Ends Here */

/* Personal Loan Section Starts Here */

.verified-partners-box {
    width: 70px;
}

.verified-partners-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border: 1px solid #dcdcdc !important;
    border-radius: 8px;
    box-shadow: none !important;
    margin-bottom: 8px;
}

/* Personal Loan Section Ends Here */

/* View Subscription Section Starts Here */

/* .short-logo-field {
    border: 1px solid #dedddd !important;
    border-radius: 6px !important;
    padding: 24px 22px !important;
    width: 112px;
    height: 112px;
} */
/* View Subscription Section Ends Here */

.carousalcenter.MuiTypography-root {
    text-align: center !important;
    font-size: 26px !important;
}

.homepage-mobile-footer {
    background-color: #1d1d1d;
    height: auto;
    padding: 20px 20px 0 20px;
    margin: 0 -16px;
}

.usefullink-container {
    margin-top: 4px;
    margin-bottom: 10px;
}

.link-container {
    margin: 8px;
}

.link-container a {
    margin-right: 10px;
}

.support-link {
    margin-bottom: '20px';
    display: 'block';
}

@media only screen and (min-width: 1280px) {
    .b2b-services-img {
        width: 100% !important;
        height: 250px !important;
    }

    .pagination-gap .MuiPagination-ul{
        gap: 10px;
    }

    .pagination-gap .MuiPagination-ul .MuiButtonBase-root.MuiPaginationItem-root{
        font-size: 16px !important;
        padding: 5px !important;
    }
}

.credit-services-card {
    max-width: 100%;
    margin: auto;
    margin-top: 20px;
    border-radius: 8px !important;
    box-shadow: none !important;
}

.credit-services-content {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px 20px !important;
    color: white;
    /* Text color on top of the background image */
}

.essential-services-card {
    max-width: 100%;
    margin: auto;
    margin-top: 0px;
    border-radius: 8px !important;
    box-shadow: none !important;
}

.essential-services-content {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px 20px !important;
    color: white;
    /* Text color on top of the background image */
}

.freeportfolio-services-content {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px 20px !important;
    color: white;
    /* Text color on top of the background image */
}

.post-job-content {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px 20px !important;
    color: white;
    /* Text color on top of the background image */
}

.learn-more-btn {
    border-radius: 6px !important;
    background: #fff !important;
    color: #864ffd !important;
    font-size: 13px !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    font-family: 'Sk-Modernist' !important;
}

.digital-marketing-card {
    max-width: 100%;
    margin: auto;
    margin-top: 20px;
    border-radius: 8px !important;
    box-shadow: none !important;
}

.digital-marketing-content {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px 20px !important;
    color: white;
    /* Text color on top of the background image */
}

/* / Chrome, Safari, Edge, Opera / */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* / Firefox / */
input[type='number'] {
    -moz-appearance: textfield;
}

.carousel-container .slide {
    padding-left: 10px;
    padding-right: 10px;
}

/* Preregister Modal Starts Here */

.pre-register-modal-container {
    padding-top: 0px !important;
}

.pre-register-modal-container .modal-content {
    width: 100%;
    /* text-align: center; */
}



.pre-register-modal-container .modal-title {
    font-family: 'Sk-Modernist' !important;
    font-size: 33px !important;
    font-weight: 700 !important;
    line-height: 30px;
    text-align: center !important;
    margin-bottom: 9px !important;
    color: #000;
    width: 96%;
    padding-left: 4%;
}

.pre-register-modal-container .modal-subtitle {
    font-family: 'Sk-Modernist' !important;
    font-size: 22px !important;
    font-weight: 700 !important;
    line-height: 26.4px !important;
    text-align: center !important;
    margin-bottom: 25px !important;
    color: #000 !important;
}

.pre-register-modal-container .features-outer-box {
    background-color: #864FFD;
    /* padding: 30px; */
    padding: 15px;
    border-radius: 20px;
    margin-bottom: 10px;
}



.pre-register-modal-container .features-box {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 20px;
    color: #000;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    position: relative;
    gap: 20px;
    /* align-items: center; */
}

/* .features-center-box::before {
    content: "";
    display: block;
    background-image: url('assets/get-in-touch-bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 90%;
    position: absolute;
    left: 0px;
} */

.team-box {
    display: flex;
    bottom: 60px;
    position: relative;
    align-items: flex-end;
    width: 160px;
}

.thumbsup-box {
    display: flex;
    position: relative;
    bottom: 0px;
    align-items: flex-end;
    width: 200px;
    align-content: flex-end;
}

.thumbsup-icon {
    width: 200px;
    height: 200px;
}


.pre-register-modal-container .feature {
    display: flex;
    align-items: start;
    margin-bottom: 10px;
    /* gap: 10px; */
}

.pre-register-modal-container .icon {
    margin-right: 20px;
}

.pre-register-modal-container .website-icon {
    width: 62px;
    height: 62px;
}

.pre-register-modal-container .social-media-icon {
    width: 62px;
    height: 62px;
}

.pre-register-modal-container .catalogue-icon {
    width: 62px;
    height: 62px;
}

.pre-register-modal-container .feature .feature-title {
    font-family: 'Sk-Modernist' !important;
    font-size: 17px;
    font-weight: 700;
    line-height: 21.6px;
    text-align: left;
    margin-bottom: 9px;
    color: #000;
}

.pre-register-modal-container .personalised-web-feature .feature-title {
    color: #1843A0 !important;
}

.pre-register-modal-container .social-media-feature .feature-title {
    color: #864FFD !important;
}

.pre-register-modal-container .free-digital-feature .feature-title {
    color: #61A3F2 !important;
}

.pre-register-modal-container .feature-description {
    font-family: 'Gotham' !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #000;
}

.pre-register-modal-container .divider {
    margin: 10px 0;
    border: 1px solid #0000001A;
    opacity: 100%;
}






.pre-register-modal-container .features-footer-text {
    font-family: 'Sk-Modernist' !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 26.4px !important;
    text-align: center !important;
    color: #fff;
}

.pre-register-modal-container .start-onboarding-button {
    background-color: #1843A0 !important;
    border-radius: 6px !important;
    color: white !important;
    padding: 12px 35px !important;
    font-family: 'Sk-Modernist' !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 21.6px !important;
    text-align: left !important;
    text-transform: capitalize !important;
    width: max-content !important;
    text-align: center !important;
    margin: 0 auto !important;
}

.pre-register-modal-container .start-onboarding-button:hover {
    background-color: #1843a0c6;
}

.pre-register-modal-container .digital-packages-link {
    display: block !important;
    margin-top: 10px !important;
    color: #1843A0 !important;
    text-decoration: underline !important;
    font-family: Sk-Modernist !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 21.6px !important;
    text-align: center !important;

}

/* Preregister Modal Ends Here */

@media (min-width: 768px) and (max-width: 990px) {
    .b2b-category-text {
        font-size: 20px !important;
        left: 20px;
        position: relative;
        top: 38px !important;
        max-width: 70%;
    }

    .b2b-category-button {
        left: 20px;
        position: relative;
        top: 32px !important;
    }

    .team-box {
        display: flex;
        bottom: 60px;
        position: relative;
        align-items: flex-end;
        width: 100px;
    }

    .thumbsup-box {
        display: flex;
        position: relative;
        bottom: 0px;
        align-items: flex-end;
        width: 150px;
        align-content: flex-end;
    }

    .thumbsup-icon {
        width: 150px;
        height: 150px;
    }

    .pagination-gap .MuiPagination-ul{
        gap: 10px;
    }

    .pagination-gap .MuiPagination-ul .MuiButtonBase-root.MuiPaginationItem-root{
        font-size: 16px !important;
        padding: 5px !important;
    }
}

@media (min-width: 785px) and (max-width: 1023px) {
    /* .subscription-content {
        display: flex;
        align-items: center;
        background-image: url('assets/plan-web-banner.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 237px;
    } */

    /* .subscription-content .subscription-text {
        font-size: 20px !important;
    } */

    .subscription-content .subscription-text br {
        display: none !important;
    }

    .profile-margin-top {
        margin-top: 100px;
    }

    .subscription-content {
        background-image: url('assets/plan-web-banner.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 154px;
    }

    .subscription-content .subscription-text {
        position: relative;
        top: 68px;
        left: 60px;
        font-size: 20px !important;
    }

    .category-content {
        background-image: url('assets/b2b-category-web-bg.svg');
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        color: #fff;
        padding: 0 !important;
        min-height: 190px;
        max-height: 194px;
    }

    .pagination-gap .MuiPagination-ul{
        gap: 10px;
    }

    .pagination-gap .MuiPagination-ul .MuiButtonBase-root.MuiPaginationItem-root{
        font-size: 16px !important;
        padding: 5px !important;
    }
}

@media (max-width: 1024px) {
    .subscription-banner-web-main-box {
        display: flex;
        gap: 80px;
        left: 2%;
        position: relative;
    }

    .subscription-banner-man {
        display: none;
    }
    
    .freelancer-detail-form {
        width: 100%;
        margin-top: 50px !important;
    }

    .b2b-detail-form {
        width: 100%;
        margin-top: 50px !important;
    }

    .profile-margin-top {
        margin-top: 100px;
    }
}

@media (max-width: 320px) {
    .category-content {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        color: #fff;
        height: 126px;
        padding: 0 !important;
    }

    .b2b-category-text {
        font-size: 14px !important;
        left: 9px;
        position: relative;
        top: 17px;
    }

    .b2b-category-button {
        left: 10px;
        position: relative;
        top: 9px;
        padding: 6px 12px !important;
        font-size: 12px !important;
    }
}

@media (max-width: 767px) {
    .btn-enquire-now {
        color: #864ffd !important;
        font-size: 14px !important;
        text-transform: none !important;
        text-decoration: underline !important;
        cursor: pointer;
        font-weight: 600 !important;
        font-family: 'Sk-Modernist' !important;
        padding: 0px !important;
    }

    .subscription-banner-web-content {
        background: linear-gradient(90deg, #1843A0 0%, #864FFD 100%);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        color: white;
        height: auto;
        position: relative;
    }

    .subscription-banner-web-main-box {
        display: flex;
        gap: 0px;
        left: 0;
        position: relative;
    }

    .subscription-banner-web-box .sub-box {
        display: flex;
        align-items: start;
        gap: 18px;
        flex-direction: column;
    }

    .subscription-banner-man {
        display: none;
    }

    .subscription-banner-web-box .subscription-banner-web-text {
        font-family: 'Sk-Modernist' !important;
        font-size: 25px !important;
        font-weight: 700 !important;
        line-height: 30px;
        color: #f2f2f2 !important;
        margin-bottom: 22px !important;
    }

    .subscription-banner-web-box .subscription-banner-web-text br {
        display: none !important;
    }

    .catalog-carousel .carousel .slider-wrapper {
        margin-bottom: 35px;
    }

    .fs-34.fs-m-20 {
        font-size: 20px !important;
    }

    .fs-18.fs-m-14 {
        font-size: 14px !important;
    }

    .b2b-list-item .category-media {

        border-radius: 50%;
        height: 75px;
        width: 75px !important;
    }

    .pre-register-modal-container .modal-title {
        font-family: 'Sk-Modernist' !important;
        font-size: 26px !important;
        margin-top: 10px;
        margin-bottom: 2px !important;
    }

    .pre-register-modal-container .modal-subtitle {
        font-size: 18px !important;
        margin-bottom: 15px !important;
    }

    .pre-register-modal-container .features-footer-text {
        font-family: 'Sk-Modernist' !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        line-height: normal !important;
        text-align: center !important;
    }

    .pre-register-modal-container .features-box {
        display: flex;
        flex-direction: column !important;
        margin-bottom: 10px;
    }

    .team-box {
        display: none;
    }

    .thumbsup-box {
        display: none;
    }

    .pre-register-modal-container .website-icon {
        width: 44px;
        height: 44px;
    }

    .pre-register-modal-container .social-media-icon {
        width: 44px;
        height: 44px;
    }

    .pre-register-modal-container .catalogue-icon {
        width: 44px;
        height: 44px;
    }

    .pre-register-modal-container {
        padding-top: 0px !important;
        padding: 10px !important;
    }

    .button-close {
        right: 7px !important;
        z-index: 1000 !important;
        background-color: #fff !important;
    }


    .pre-register-modal-container .icon {
        margin-right: 10px;
    }

    .pre-register-modal-container .features-outer-box {
        background-color: #864FFD;
        margin-bottom: 15px;
    }

    .pre-register-modal-container .features-box {
        background-color: #ffffff;
        padding: 15px;
    }

    .pre-register-modal-container .feature {
        display: flex;
        align-items: start;
        margin-bottom: 0px;
        /* gap: 10px; */
    }

    .pre-register-modal-container .divider {
        margin: 10px 0;
        border: 1px solid #0000001A;
        opacity: 100%;
    }

    .pre-register-modal-container .feature .feature-title {
        font-family: 'Sk-Modernist' !important;
        font-size: 14px;
        line-height: normal;
        text-align: left;
        margin-bottom: 5px;
    }

    .pre-register-modal-container .feature-description {
        font-family: 'Gotham' !important;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #393939;
    }

    .pre-register-modal-container .feature-description br {
        display: none !important;
    }

    .pre-register-modal-container .start-onboarding-button {
        background-color: #1843A0 !important;
        border-radius: 6px !important;
        color: white !important;
        padding: 8px 30px !important;
        font-family: 'Sk-Modernist' !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        line-height: 21.6px !important;
        text-align: left !important;
        text-transform: capitalize !important;
        width: max-content !important;
        text-align: center !important;
        margin: 0 auto !important;
    }

    .pre-register-modal-container .digital-packages-link {
        display: block !important;
        margin-top: 10px !important;
        color: #1843A0 !important;
        text-decoration: underline !important;
        font-family: Sk-Modernist !important;
        font-size: 14px !important;
        font-weight: 700 !important;
        line-height: 21.6px !important;
        text-align: center !important;
    }

    .start-growing-btn {
        border-radius: 6px !important;
        background: #fff !important;
        color: #864ffd !important;
        font-size: 16px !important;
        text-transform: capitalize !important;
        font-weight: 700 !important;
        font-family: 'Sk-Modernist' !important;
        padding: 8px 16px !important;
    }
}

@media (min-width: 768px) {
    .package-box .caption {
        font-family: 'Sk-Modernist' !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 16.8px !important;
        text-align: left !important;
        color: #757575 !important;
        position: absolute;
        bottom: 20px;
    }

    .package-box .note-box {
        position: absolute;
        bottom: 50px;
        right: 17px;
        left: 17px;
    }

    .subscription-content {
        background-image: url('assets/plan-web-banner.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 153px;
    }

    .subscription-content .subscription-text {
        position: relative;
        top: 68px;
        left: 60px;
        font-size: 20px !important;
    }

    .subscription-content .subscription-text br {
        display: none !important;
    }

    .category-card {
        width: 100%;
        margin: auto;
        margin-top: 20px;
        border-radius: 8px !important;
        box-shadow: none !important;
    }

    .category-content {
        background-image: url('assets/b2b-category-web-bg.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 151px;
    }

    .b2b-category-text {
        position: relative;
        top: 44px;
        left: 20px;
        font-size: 20px !important;
    }

    .b2b-category-text br {
        display: none !important;
    }

    .b2b-category-button {
        position: relative;
        top: 45px;
        left: 20px;
    }

    .business-testimonial-content {
        background-image: url('assets/Business-List-Header764x300px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 148px;
    }

    .business-testimonial-text {
        font-size: 23px !important;
        position: relative;
        top: 50px;
        left: 60px;
    }

    .freelance-category-content {
        background-image: url('assets/freelance-web-category-banner.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 155px;
    }

    .freelance-category-text {
        position: relative;
        top: 40px;
        left: 20px;
        font-size: 20px !important;
        width: 60%;
    }

    .freelance-category-button {
        position: relative;
        top: 32px;
        left: 20px;
    }

    .freelance-category-content br {
        display: none !important;
    }

    .freelance-testimonial-web-content {
        background-image: url('assets/JobSeeker-List-Header2340x400px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 148px;
    }

    .freelance-testimonial-web-text {
        font-size: 23px !important;
        position: relative;
        top: 50px;
        left: 60px;
    }

    .job-listing-content {
        background-image: url('assets/JobsNearyou-List-Header764x300px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 156px;
    }

    .job-listing-content br {
        display: none !important;
    }

    .job-listing-text {
        position: relative;
        top: 40px;
        left: 20px;
        font-size: 20px !important;
    }

    .job-listing-button {
        position: relative;
        top: 53px;
        left: 20px;
    }

    .elynker-services-text {
        top: 20px;
    }
}

@media (min-width: 991px) {
    .freelance-testimonial-web-content {
        background-image: url('assets/JobSeeker-List-Header2340x400px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 141px;
    }

    .freelance-testimonial-web-text {
        font-size: 23px !important;
        position: relative;
        top: 45px;
        left: 60px;
    }

    .business-testimonial-content {
        background-image: url('assets/Business-List-Header764x300px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 141px;
    }

    .business-testimonial-text {
        font-size: 23px !important;
        position: relative;
        top: 45px;
        left: 60px;
    }

    .category-content {
        background-image: url('assets/b2b-category-web-bg.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        max-height: 195px;
    }

    .b2b-category-text {
        position: relative;
        top: 44px;
        left: 20px;
        font-size: 20px !important;
    }
}

@media (min-width: 1024px) {
    .freelance-testimonial-web-content {
        background-image: url('assets/JobSeeker-List-Header2340x400px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 191px;
    }

    .freelance-testimonial-web-text {
        font-size: 25px !important;
        position: relative;
        top: 65px;
        left: 80px;
    }

    .business-testimonial-web-content {
        background-image: url('assets/Business-List-Header2340x400px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 192px;
    }

    .business-testimonial-web-text {
        font-size: 25px !important;
        position: relative;
        top: 65px;
        left: 80px;
    }

    .fs-20.fs-md-32 {
        font-size: 32px !important;
    }

    .category-web-content {
        background-image: url('assets/b2b-category-web-bg.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 192px;
    }

    .category-web-content .flex-content {
        position: relative;
        top: 70px;
        left: 40px;
        display: flex !important;
        align-items: center !important;
        gap: 20px;
    }

    .category-web-content .flex-content .b2b-category-web-text {
        font-size: 25px !important;
    }

    .freelancer-detail-accordion {
        margin: 15px -16px !important;
        border-top: 2px solid #dedede;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .subscription-web-content {
        background-image: url('assets/plan-web-banner.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 195px;
        position: relative;
        /* transform: translateY(-22px); */
    }

    .subscription-web-text {
        font-size: 25px !important;
        position: relative;
        top: 90px;
        left: 80px;
    }

    .fl-category-web-content {
        background-image: url('assets/freelance-web-category-banner.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 00px !important;
        color: white;
        height: 191px;
    }

    .fl-category-web-content .flex-content {
        position: relative;
        top: 60px;
        left: 40px;
        display: flex !important;
        align-items: center !important;
        gap: 20px;
    }

    .fl-category-web-content .flex-content .fl-category-text {
        font-size: 25px !important;
    }

    .job-listing-web-content {
        background-image: url('assets/JobsNearyou-List-Header2340x400px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 00px !important;
        color: white;
        height: 191px;
    }

    .job-listing-web-content .flex-content {
        position: relative;
        top: 60px;
        left: 40px;
        display: flex !important;
        align-items: center !important;
        gap: 0px;
    }

    .job-listing-web-content .flex-content .job-listing-web-text {
        font-size: 25px !important;
    }

    .profile-margin-top {
        margin-top: 30px;
    }

    .job-detail-custom-container {
        width: 75% !important;
        margin: 0 auto !important;
    }

    .elynker-services-web-card {
        width: 100%;
        margin: auto;
        margin-top: 8px;
        border-radius: 8px !important;
        box-shadow: none !important;
        position: relative;
    }

    .elynker-services-web-content {
        background-image: url('assets/Tools-List-Header2340x400px.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        color: white;
        height: 170px;
    }

    .elynker-services-web-text {
        top: 32px;
    }

    .package-box .caption {
        font-family: 'Sk-Modernist' !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 16.8px !important;
        text-align: left !important;
        color: #757575 !important;
        cursor: pointer !important;
    }
}

@media (min-width: 1025px) and (max-width: 1279px) {
    .category-web-content {
        background-image: url('assets/b2b-category-web-bg.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 239px;
    }
}

@media (min-width: 1280px) {
    .freelance-testimonial-web-content {
        background-image: url('assets/JobSeeker-List-Header2340x400px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 243px;
    }

    .freelance-testimonial-web-text {
        font-size: 25px !important;
        position: relative;
        top: 75px;
        left: 80px;
    }

    .category-web-content {
        background-image: url('assets/b2b-category-web-bg.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 239px;
    }

    .category-web-content .flex-content {
        position: relative;
        top: 90px;
        left: 60px;
        display: flex !important;
        align-items: center !important;
        gap: 20px;
    }

    .category-web-content .flex-content .b2b-category-web-text {
        font-size: 28px !important;
    }

    .subscription-web-content {
        background-image: url('assets/plan-web-banner.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 233px;
    }

    .subscription-web-text {
        font-size: 25px !important;
        position: relative;
        top: 110px;
        left: 80px;
    }

    .business-testimonial-web-content {
        background-image: url('assets/Business-List-Header2340x400px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 240px;
    }

    .business-testimonial-web-text {
        font-size: 25px !important;
        position: relative;
        top: 75px;
        left: 80px;
    }

    .fl-category-web-content {
        background-image: url('assets/freelance-web-category-banner.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 241px;
    }

    .fl-category-web-content .flex-content {
        position: relative;
        top: 95px;
        left: 60px;
        display: flex !important;
        align-items: center !important;
        gap: 20px;
    }

    .fl-category-web-content .flex-content .fl-category-text {
        font-size: 28px !important;
    }

    .job-listing-web-content {
        background-image: url('assets/JobsNearyou-List-Header2340x400px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 00px !important;
        color: white;
        height: 241px;
    }

    .job-listing-web-content .flex-content {
        position: relative;
        top: 75px;
        left: 60px;
        display: flex !important;
        align-items: center !important;
        gap: 0px;
    }

    .job-listing-web-content .flex-content .job-listing-web-text {
        font-size: 28px !important;
    }

    .elynker-services-web-content {
        background-image: url('assets/Tools-List-Header2340x400px.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        color: white;
        height: 215px;
    }

    .elynker-services-web-text {
        top: 50px;
    }
}

@media (min-width: 1366px) {
    .freelance-testimonial-web-content {
        background-image: url('assets/JobSeeker-List-Header2340x400px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 258px;
    }

    .freelance-testimonial-web-text {
        font-size: 30px !important;
        position: relative;
        top: 90px;
        left: 80px;
    }

    .business-testimonial-web-content {
        background-image: url('assets/Business-List-Header2340x400px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 260px;
    }

    .business-testimonial-web-text {
        font-size: 30px !important;
        position: relative;
        top: 90px;
        left: 80px;
    }

    .fl-category-web-content {
        background-image: url('assets/freelance-web-category-banner.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 258px;
    }

    .fl-category-web-content .flex-content {
        position: relative;
        top: 100px;
        left: 60px;
        display: flex !important;
        align-items: center !important;
        gap: 20px;
    }

    .fl-category-web-content .flex-content .fl-category-text {
        font-size: 30px !important;
    }

    .category-web-content {
        background-image: url('assets/b2b-category-web-bg.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 258px;
    }

    .category-web-content .flex-content {
        position: relative;
        top: 105px;
        left: 60px;
        display: flex !important;
        align-items: center !important;
        gap: 20px;
    }

    .category-web-content .flex-content .b2b-category-web-text {
        font-size: 30px !important;
    }

    .job-listing-web-content {
        background-image: url('assets/JobsNearyou-List-Header2340x400px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 258px;
    }

    .job-listing-web-content .flex-content {
        position: relative;
        top: 85px;
        left: 60px;
        display: flex !important;
        align-items: center !important;
        gap: 20px;
    }

    .job-listing-web-content .flex-content .job-listing-web-text {
        font-size: 30px !important;
    }

    .subscription-web-content {
        background-image: url('assets/plan-web-banner.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 263px;
    }

    .subscription-web-text {
        font-size: 30px !important;
        position: relative;
        top: 110px;
        left: 80px;
    }
}

@media (min-width: 1440px) {
    .freelance-testimonial-web-content {
        background-image: url('assets/JobSeeker-List-Header2340x400px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 273px;
    }

    .freelance-testimonial-web-text {
        font-size: 30px !important;
        position: relative;
        top: 100px;
        left: 80px;
    }

    .business-testimonial-web-content {
        background-image: url('assets/Business-List-Header2340x400px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 274px;
    }

    .business-testimonial-web-text {
        font-size: 30px !important;
        position: relative;
        top: 100px;
        left: 80px;
    }

    .fl-category-web-content {
        background-image: url('assets/freelance-web-category-banner.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 272px;
    }

    .fl-category-web-content .flex-content {
        position: relative;
        top: 95px;
        left: 60px;
        display: flex !important;
        align-items: center !important;
        gap: 20px;
    }

    .fl-category-web-content .flex-content .fl-category-text {
        font-size: 30px !important;
        width: 43% !important;
    }

    .fl-category-web-content .flex-content .post-requirement-btn {
        font-size: 16px !important;
    }

    .category-web-content {
        background-image: url('assets/b2b-category-web-bg.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 276px;
    }

    .category-web-content .flex-content {
        position: relative;
        top: 115px;
        left: 60px;
        display: flex !important;
        align-items: center !important;
        gap: 20px;
    }

    .category-web-content .flex-content .b2b-category-web-text {
        font-size: 30px !important;
        width: 43% !important;
    }

    .category-web-content .flex-content .post-requirement-btn {
        font-size: 16px !important;
    }

    .job-listing-web-content {
        background-image: url('assets/JobsNearyou-List-Header2340x400px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 272px;
    }

    .job-listing-web-content .flex-content {
        position: relative;
        top: 90px;
        left: 60px;
        display: flex !important;
        align-items: center !important;
        gap: 0px;
    }

    .job-listing-web-content .flex-content .job-listing-web-text {
        font-size: 30px !important;
        width: 43% !important;
    }

    .job-listing-web-content .flex-content .post-requirement-btn {
        font-size: 16px !important;
    }

    .subscription-web-content {
        background-image: url('assets/plan-web-banner.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 283px;
    }

    .subscription-web-text {
        font-size: 30px !important;
        position: relative;
        top: 120px;
        left: 80px;
    }
}

@media (min-width: 1536px) {
    .MuiContainer-root {
        max-width: none !important;
    }

    .category-web-content {
        background-image: url('assets/b2b-category-web-bg.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 292px;
    }

    .category-web-content .flex-content {
        position: relative;
        top: 125px;
        left: 60px;
        display: flex !important;
        align-items: center !important;
        gap: 20px;
    }

    .category-web-content .flex-content .b2b-category-web-text {
        font-size: 30px !important;
        width: 40% !important;
    }

    .category-web-content .flex-content .post-requirement-btn {
        font-size: 17px !important;
        padding: 13px 25px !important;
    }

    .fl-category-web-content {
        background-image: url('assets/freelance-web-category-banner.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 291px;
    }

    .fl-category-web-content .flex-content {
        position: relative;
        top: 105px;
        left: 60px;
        display: flex !important;
        align-items: center !important;
        gap: 20px;
    }

    .fl-category-web-content .flex-content .fl-category-text {
        font-size: 30px !important;
        width: 40% !important;
    }

    .fl-category-web-content .flex-content .post-requirement-btn {
        font-size: 17px !important;
        padding: 13px 25px !important;
    }

    .job-listing-web-content {
        background-image: url('assets/JobsNearyou-List-Header2340x400px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 291px;
    }

    .job-listing-web-content .flex-content {
        position: relative;
        top: 105px;
        left: 60px;
        display: flex !important;
        align-items: center !important;
        gap: 0px;
    }

    .job-listing-web-content .flex-content .job-listing-web-text {
        font-size: 30px !important;
        width: 40% !important;
    }

    .job-listing-web-content .flex-content .post-requirement-btn {
        font-size: 17px !important;
        padding: 13px 25px !important;
    }

    .freelance-testimonial-web-content {
        background-image: url('assets/JobSeeker-List-Header2340x400px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 291px;
    }

    .freelance-testimonial-web-text {
        font-size: 30px !important;
        position: relative;
        top: 115px;
        left: 80px;
    }

    .business-testimonial-web-content {
        background-image: url('assets/Business-List-Header2340x400px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 291px;
    }

    .business-testimonial-web-text {
        font-size: 30px !important;
        position: relative;
        top: 115px;
        left: 80px;
    }

    .subscription-web-content {
        background-image: url('assets/plan-web-banner.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 301px;
    }

    .subscription-web-text {
        font-size: 30px !important;
        position: relative;
        top: 135px;
        left: 80px;
    }
}

@media (min-width: 1680px) {
    .category-web-content {
        background-image: url('assets/b2b-category-web-bg.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 320px;
    }

    .category-web-content .flex-content {
        position: relative;
        top: 135px;
        left: 60px;
        display: flex !important;
        align-items: center !important;
        gap: 20px;
    }

    .category-web-content .flex-content .b2b-category-web-text {
        font-size: 35px !important;
        width: 40% !important;
    }

    .category-web-content .flex-content .post-requirement-btn {
        font-size: 17px !important;
        padding: 13px 25px !important;
    }

    .freelance-testimonial-web-content {
        background-image: url('assets/JobSeeker-List-Header2340x400px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 319px;
    }

    .freelance-testimonial-web-text {
        font-size: 35px !important;
        position: relative;
        top: 120px;
        left: 80px;
    }

    .subscription-web-content {
        background-image: url('assets/plan-web-banner.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 327px;
    }

    .subscription-web-text {
        font-size: 35px !important;
        position: relative;
        top: 140px;
        left: 80px;
    }

    .business-testimonial-web-content {
        background-image: url('assets/Business-List-Header2340x400px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 316px;
    }

    .business-testimonial-web-text {
        font-size: 30px !important;
        position: relative;
        top: 120px;
        left: 80px;
    }

    .fl-category-web-content {
        background-image: url('assets/freelance-web-category-banner.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 320px;
    }

    .fl-category-web-content .flex-content {
        position: relative;
        top: 115px;
        left: 60px;
        display: flex !important;
        align-items: center !important;
        gap: 20px;
    }

    .fl-category-web-content .flex-content .fl-category-text {
        font-size: 35px !important;
        width: 40% !important;
    }

    .fl-category-web-content .flex-content .post-requirement-btn {
        font-size: 17px !important;
        padding: 13px 25px !important;
    }

    .job-listing-web-content {
        background-image: url('assets/JobsNearyou-List-Header2340x400px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 320px;
    }

    .job-listing-web-content .flex-content {
        position: relative;
        top: 100px;
        left: 60px;
        display: flex !important;
        align-items: center !important;
        gap: 20px;
    }

    .job-listing-web-content .flex-content .job-listing-web-text {
        font-size: 35px !important;
        width: 40% !important;
    }

    .job-listing-web-content .flex-content .post-requirement-btn {
        font-size: 17px !important;
        padding: 13px 25px !important;
    }
}

@media (min-width: 1696px) and (max-width: 1919px) {
    .category-web-content {
        background-image: url('assets/b2b-category-web-bg-767-2.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 320px;
    }
}

@media (min-width: 1920px) {
    .category-web-content {
        background-image: url('assets/b2b-category-web-bg.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 368px;
    }

    .category-web-content .flex-content {
        position: relative;
        top: 165px;
        left: 80px;
        display: flex !important;
        align-items: center !important;
        gap: 20px;
    }

    .category-web-content .flex-content .b2b-category-web-text {
        font-size: 35px !important;
        width: 40% !important;
    }

    .category-web-content .flex-content .post-requirement-btn {
        font-size: 17px !important;
        padding: 13px 25px !important;
    }

    .fl-category-web-content {
        background-image: url('assets/freelance-web-category-banner.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 366px;
    }

    .fl-category-web-content .flex-content {
        position: relative;
        top: 135px;
        left: 80px;
        display: flex !important;
        align-items: center !important;
        gap: 20px;
    }

    .fl-category-web-content .flex-content .fl-category-text {
        font-size: 35px !important;
        width: 40% !important;
    }

    .fl-category-web-content .flex-content .post-requirement-btn {
        font-size: 17px !important;
        padding: 13px 25px !important;
    }

    .job-listing-web-content {
        background-image: url('assets/JobsNearyou-List-Header2340x400px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 368px;
    }

    .job-listing-web-content .flex-content {
        position: relative;
        top: 125px;
        left: 80px;
        display: flex !important;
        align-items: center !important;
        gap: 20px;
    }

    .job-listing-web-content .flex-content .job-listing-web-text {
        font-size: 35px !important;
        width: 40% !important;
    }

    .job-listing-web-content .flex-content .post-requirement-btn {
        font-size: 17px !important;
        padding: 13px 25px !important;
    }

    .subscription-web-content {
        background-image: url('assets/plan-web-banner.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 375px;
    }

    .subscription-web-text {
        font-size: 40px !important;
        position: relative;
        top: 160px;
        left: 80px;
    }

    .freelance-testimonial-web-content {
        background-image: url('assets/JobSeeker-List-Header2340x400px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 366px;
    }

    .freelance-testimonial-web-text {
        font-size: 40px !important;
        position: relative;
        top: 140px;
        left: 80px;
    }

    .business-testimonial-web-content {
        background-image: url('assets/Business-List-Header2340x400px.png');
        /* background-size: contain; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px !important;
        color: white;
        height: 366px;
    }

    .business-testimonial-web-text {
        font-size: 40px !important;
        position: relative;
        top: 140px;
        left: 80px;
    }
}

.pagination-gap {
    display: flex;
    justify-content: center;
    width: 100%;
}

.pagination-gap .MuiPagination-ul{
    gap: 2px;
}

.pagination-gap .MuiPagination-ul .MuiButtonBase-root.MuiPaginationItem-root{
    font-size: 16px !important;
    padding: 5px !important;
    color: #864FFD;
}

.pagination-gap .MuiPagination-ul .MuiButtonBase-root.Mui-selected {
    background-color: #864ffd !important;
    color: #fff !important;
}

.slide {
    padding-top: 0rem;
  }
  
  .carousel .control-dots {
    z-index: 3;
  }
  
  .ind {
    width: 20px;
    height: 20px;
    list-style: none;
    border: 4px solid #f5f5f5;
    transform: rotate(135deg);
    display: inline-block;
    cursor: pointer;
    position: relative;
    z-index: 100;
  }
  
  .ind.active {
    background-color: #000;
    transition: 500ms 200ms;
  }
  
  .ind:not(:last-child) {
    margin-right: 1.4rem;
  }
  
  .carousel .carousel-status {
    left: 0;
    right: 0;
    text-align: center;
    color: #000;
    font-size: 1.4rem;
    text-shadow: none;
    margin-top: 1rem;
    text-transform: uppercase;
    font-weight: 700;
  }


  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }
  
  td {
    height: 250px;
    padding: 10px;
  }

  .image-column {
    width: 50%; /* First column takes up 50% of the table width */
    text-align: center;
  }
  
  .text-column {
    width: 50%; /* Second column also takes up 50% of the table width */
    vertical-align: middle;
  }

  .item-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover; /* Ensures the image fills the container while preserving aspect ratio */
  }

  .item-title {
    font-size: 20px;
    margin: 0;
  }

  .item-description {
    font-size: 16px;
    color: #555;
  }

  .item-price {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }