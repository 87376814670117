


/* .call-logo {
    position: absolute;
    top: 34px;
    right: 17px;
} */

.primary-color-1 {
    background-color: #1843A0 !important;
}

.primary-color-2 {
    background-color: #864FFD !important;
}

.secondary-color-1 {
    background-color: #61A3F2 !important;
}

.secondary-black {
    background-color: #393939 !important;
}


.MuiTelInput-TextField .MuiTypography-root {
    font-family: "Gotham" !important;
    color: black !important;
    font-size: 14px;
    
}

.MuiTelInput-IconButton {
    padding: 0 8px 0 0 !important;
}

.MuiTelInput-TextField .MuiOutlinedInput-input {
    padding-left: 0px !important;
}
