/* .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    color: #FF0000 !important;
} */

.MuiStepLabel-label.Mui-active {
    color: rgb(134, 79, 253) !important;
}

.MuiStepLabel-label.Mui-completed {
    color: rgb(117, 117, 117) !important;
}