.MuiToggleButtonGroup-root {
    width: 100%;
    height: 46px;
}

.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:last-of-type) {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    width: 50%;
}

.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped {
    background: #D9D9D9;
    color: #393939;
    font-size: 14px;
    font-weight: 700;
    text-transform: none;
}



/* @media (hover: none) { */
    /* .MuiButtonBase-root-MuiToggleButton-root.Mui-selected:hover {
        background: #416AD4;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.14);
        text-transform: capitalize;
    } */
/* } */


.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type) {
    margin-left: -1px;
    border-left: 1px solid transparent;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    width: 50%;
}

/* .custom-toggle:hover {
    color: #fff !important;
    background-color: #416bd4d0 !important;
} */


.custom-toggle.Mui-selected {
    color: #fff !important;
    background-color: #416AD4 !important;
}


