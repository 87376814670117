
.text-para {
    color: #727272;
  }

/* .call-logo {
    position: absolute;
    top: 34px;
    right: 17px;
} */

.primary-color-1 {
    background-color: #1843A0 !important;
}

.primary-color-2 {
    background-color: #864FFD !important;
}

.secondary-color-1 {
    background-color: #61A3F2 !important;
}

.secondary-black {
    background-color: #393939 !important;
}


.inputOtp {
    text-align: center;
    color: #727272;;
}

.MuiOutlinedInput-notchedOutline {
    border-radius: 6px !important;
    border: 1px solid #D4D4D4 !important;
}



  .Mui-focused .MuiOutlinedInput-notchedOutline  {
    border: 1px solid #416AD4 !important;
    border-radius: 6px !important;
}